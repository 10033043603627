<template>
  <div>
    <b-modal id="modal-teknosa-stash-banner-payment"
             hide-header-close
             hide-footer
             centered
             size="lg">
      <template v-slot:modal-header>
        <img src="/image/teknosa-banner/teknosacell-modal.svg"
             alt="teknosacell"
             class="img-fluid teknosa-modal-image" />
        <div class="d-flex text-white">
          <div class="flex-grow-1 d-flex flex-column text-center">
            <div class="font-weight-bold title text-uppercase">
              {{ $ml.get('confirmOfPurchase') }}
            </div>
            <div class="subtitle">{{ $ml.get('yourPaymentOptions') }}</div>
          </div>
        </div>
      </template>
      <b-container>
        <div class="d-flex flex-column align-items-center justify-content-center text-center mb-10">
          <div class="title mb-5 text-uppercase max-555">
            {{ $ml.get('yourPackage') }}
          </div>
          <div class="packet-choose pt-3 pb-3 pr-20 pl-20 mb-5 rounded-sm max-555">
            {{ this.catalogName }}
          </div>
          <TeknosaGiftBanner class="max-555"></TeknosaGiftBanner>
          <div class="card-choose-info w-50">
            {{ $ml.get('youCanMakePurchaseByChoosingCards') }}
          </div>
        </div>

        <CreditCardListModal :isCreditCardLoading="isCreditCardLoading"
                             @addedCreditCard="addedCreditCard"
                             @verifyCreditCard="addedCreditCard"
                             :disabled="sendLoading"></CreditCardListModal>

        <!--begin::Form-->
        <b-form class="form"
                @submit.stop.prevent="onSubmit"
                :disabled="sendLoading">
          <div class="d-flex flex-column align-items-center justify-content-center text-center">
            <div class="d-flex">
              <!--begin::Checkbox-->
              <label class="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0">
                <input type="checkbox"
                       id="isApproveTermsOfUse"
                       v-model="isApproveTermsOfUse"
                       aria-describedby="isApproveTermsOfUse-input-live-feedback" />
                <span class="mr-3"></span>
              </label>
              <!--end::Checkbox-->
              <a class="d-flex btn m-0 p-0"
                 v-b-modal.modal-usage-agreement>{{
                $ml.get('acceptTermWithUrl')
              }}</a>
            </div>
            <p class="invalid-check"
               v-show="isApproveTermsOfUseError">
              {{ $ml.get('youMustAcceptTerm') }}
            </p>
            <div class="d-flex mt-5">
              <button class="d-flex btn pr-15 pl-15 mr-5 btn-danger"
                      @click="cancel()">
                {{ $ml.get('cancel') }}
              </button>
              <button type="submit"
                      class="d-flex btn pr-15 pl-15 btn-primary">
                {{ $ml.get('confirm') }}
              </button>
            </div>
          </div>
        </b-form>
        <!--end::Form-->
      </b-container>
    </b-modal>

    <TeknosaStashPaymentSuccessModal />
  </div>
</template>

<script>
import CreditCardListModal from '@/components/CreditCardListModal'
import { GET_CREDIT_CARDS } from '@/core/services/store/creditCards.module'
import ApiService from '@/core/services/api.service'
import TeknosaGiftBanner from '@/components/general/TeknosaGiftBanner'
import TeknosaStashPaymentSuccessModal from '@/components/general/TeknosaStashPaymentSuccessModal'

export default {
  name: 'TeknosaStashModal',
  data() {
    return {
      isCreditCardLoading: false,
      isApproveTermsOfUse: false,
      isApproveTermsOfUseError: false,
      hasCard: false,
      sendLoading: false,
    }
  },
  props: {
    catalogName: String,
    catalogId: String,
  },
  created() {
    this.getCreditCards()
  },
  methods: {
    getCreditCards() {
      this.isCreditCardLoading = true
      this.$store
        .dispatch(GET_CREDIT_CARDS)
        .then((res) => {
          if (res.data.length > 0) {
            this.hasCard = true
          }
          this.isCreditCardLoading = false
        })
        .catch((err) => {
          this.isCreditCardLoading = false
        })
    },
    addedCreditCard() {
      this.getCreditCards()
    },
    cancel() {
      this.$bvModal.hide('modal-teknosa-stash-banner-payment')
    },
    onSubmit() {
      if (this.hasCard === false) {
        this.$generateToast(
          this,
          'danger',
          this.$ml.get('youMustHaveLeastOneCreditCard')
        )
      } else if (this.isApproveTermsOfUse === false) {
        this.isApproveTermsOfUseError = true
      } else {
        this.isApproveTermsOfUseError = false
        this.sendLoading = true
        ApiService.post(`marketplace/${this.catalogId}/subscribe`, {
          is_renewable: 1,
        })
          .then((res) => {
            this.$bvModal.hide('modal-teknosa-stash-banner-payment')
            this.$bvModal.show('modal-paymentResult')
          })
          .catch((err) => {
            if (err == 'Error') {
              this.$generateToast(
                this,
                'danger',
                this.$ml.get('anErrorHasOccurred')
              )
            }
          })
          .finally(() => {
            this.sendLoading = false
          })
      }
    },
  },
  components: {
    CreditCardListModal,
    TeknosaGiftBanner,
    TeknosaStashPaymentSuccessModal,
  },
}
</script>

<style lang="scss">
#modal-teknosa-stash-banner-payment {
  .modal-content {
    background-color: #ffffff;
  }
  .modal-header {
    background-color: #f58220;
    display: inline;
    .title {
      font-size: 29px;
      font-weight: 700 !important;
    }
    .subtitle {
      font-size: 12px;
      font-weight: 500 !important;
    }
    .teknosa-modal-image {
      position: absolute;
      right: 20px;
      height: 65px;
    }
  }
  .modal-body {
    // background-color: #ffffff;
    .title {
      font-weight: 700;
      font-size: 17px;
    }
    .packet-choose {
      background-color: #f58220;
      color: #ffffff;
      font-weight: 600;
      font-size: 17px;
    }
    .card-choose-info {
      color: #80808f;
      font-weight: 400;
      font-size: 12px;
    }
    a {
      font-weight: 500;
      color: #464e5f;
    }
    .invalid-check {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 0.9rem;
      color: #f64e60;
    }
  }

  .max-555 {
    max-width: 555px;
    width: 100%;
  }
  @media (max-width: 767px) {
    .title {
      font-size: 18px !important;
      text-align: left !important;
    }
    .subtitle {
      font-weight: 300 !important;
      font-size: 12px !important;
      text-align: left !important;
    }
    .teknosa-modal-image {
      height: 55px !important;
    }
    .packet-choose {
      font-size: 14px !important;
    }
    .card-choose-info {
      width: 100% !important;
    }

    .form {
      height: 90px !important;
    }
  }
}
</style>