<template>
  <div>
    <b-modal
      id="modal-teknosa-stash-banner-free"
      hide-header
      hide-header-close
      hide-footer
      centered
      size="sm"
    >
      <b-container>
        <div class="d-flex flex-column mb-5">
          <div class="title mb-4">
            {{ $ml.get('subscriptionConfirmation') }}
          </div>
          <div class="choose-info">
            {{ $ml.get('freeSubscriptionPackageDefinedForYourUse') }}
          </div>
        </div>
        <!--begin::Form-->
        <b-form
          class="form"
          @submit.stop.prevent="onSubmit"
          :disabled="sendLoading"
        >
          <div
            class="d-flex flex-column align-items-center justify-content-center text-center"
          >
            <div class="d-flex">
              <!--begin::Checkbox-->
              <label
                class="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0"
              >
                <input
                  type="checkbox"
                  id="isApproveTermsOfUse"
                  @change="$v.isApproveTermsOfUse.$touch()"
                  v-model="isApproveTermsOfUse"
                  aria-describedby="isApproveTermsOfUse-input-live-feedback"
                />
                <span class="mr-3"></span>
              </label>
              <!--end::Checkbox-->
              <a
                class="d-flex btn m-0 p-0 text-left btn-usage-agreement"
                v-b-modal.modal-usage-agreement
                >{{ $ml.get('acceptfreeTermWithUrl') }}</a
              >
            </div>
            <p class="invalid-check" v-show="isApproveTermsOfUseError">
              {{ $ml.get('youMustAcceptTerm') }}
            </p>
            <div class="d-flex mt-5">
              <button type="submit" class="d-flex btn pr-15 pl-15 btn-primary">
                {{ $ml.get('confirm') }}
              </button>
            </div>
          </div>
        </b-form>
        <!--end::Form-->
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  name: 'TeknosaStashFreeModal',
  data() {
    return {
      isApproveTermsOfUse: false,
      isApproveTermsOfUseError: false,
      sendLoading: false,
    }
  },
  props: {
    catalogId: String,
  },
  created() {},
  methods: {
    onSubmit() {
      if (this.isApproveTermsOfUse === false) {
        this.isApproveTermsOfUseError = true
      } else {
        this.isApproveTermsOfUseError = false
        this.sendLoading = true
        ApiService.post(`marketplace/${this.catalogId}/subscribe`, {
          is_renewable: 1,
        })
          .then((res) => {
            this.generateToast('success', this.$ml.get('successful'))
          })
          .catch((err) => {
            if (err == 'Error') {
              this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
            }
          })
          .finally(() => {
            this.sendLoading = false
          })
      }
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
  },
  components: {},
}
</script>

<style lang="scss">
#modal-teknosa-stash-banner-free {
  .modal-content {
    background: radial-gradient(
      50% 38.76% at 50% 31.25%,
      #21e1d8 0%,
      #1bc5bd 100%
    );
    color: #ffffff;
    .container {
      padding: 0;
    }
  }
  .modal-body {
    // background-color: #ffffff;
    .title {
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
    }
    .choose-info {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
    .btn-usage-agreement {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #ffffff !important;
      text-decoration: underline !important;
    }
    .btn-usage-agreement:hover {
      color: #ffffff !important;
    }
    .invalid-check {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 0.9rem;
      color: #f64e60;
    }
  }

  .max-555 {
    max-width: 555px;
    width: 100%;
  }
}
</style>