<template>
  <Card class="credit-card-list">
    <CardLoader v-if="getLoadingStatus()">
      <rect x="0"
            y="4"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="24"
            width="150"
            height="12"
            rx="3" />
      <rect x="0"
            y="56"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="76"
            width="370"
            height="12"
            rx="3" />
      <rect x="0"
            y="96"
            width="178"
            height="12"
            rx="3" />
    </CardLoader>

    <template #header>
      <div class="row w-100 p-0 m-0">
        <div class="title col-md-6">
          <h3 class="text-dark font-size-secondary m-0">
            {{ $ml.get('addresses') }}
          </h3>
        </div>
        <div class="col-md-6 text-right card-buttons"
             v-if="!getLoadingStatus()">
          <b-button variant="danger"
                    size="sm"
                    class="rounded-sm"
                    v-if="addressList.length > 0"
                    @click="deleteSelectedAddress()">{{ $ml.get('deleteSelected') }}
          </b-button>

          <b-button variant="primary"
                    size="sm"
                    class="rounded-sm ml-2"
                    @click="openNewAddressModal()">{{ $ml.get('addNewAddress') }}</b-button>
        </div>
      </div>
    </template>

    <template v-if="!getLoadingStatus()">
      <AddressTable @openEditAddressModal="openEditAddressModal"
                    @addressListData="addressListData"
                    :showChecks="true"></AddressTable>
    </template>

    <AddNewAddressModal :items="selectedAddress"
                        :show="newAddressModal"
                        @closeModal="closeModal"></AddNewAddressModal>
  </Card>
</template>

<script>
import EventBus from '@/event-bus'
import Card from '@/components/Card'
import CardLoader from '@/view/content/CardLoader'
import AddNewAddressModal from '@/components/invoice/AddNewAddressModal'
import AddressTable from '@/components/invoice/AddressTable'

export default {
  name: 'AddressList',
  data() {
    return {
      addressList: [],
      loading: false,
      selectedAddress: null,
      newAddressModal: false,
    }
  },
  methods: {
    addressListData(payload) {
      this.addressList = payload
    },
    deleteSelectedAddress() {
      EventBus.$emit('deleteSelectedAddress')
    },
    openEditAddressModal(item) {
      this.newAddressModal = true
      this.selectedAddress = item
    },
    openNewAddressModal() {
      this.newAddressModal = true
    },
    closeModal() {
      this.newAddressModal = false
      this.selectedAddress = null
    },
    getLoadingStatus() {
      return this.loading
    },
  },
  components: {
    Card,
    CardLoader,
    AddNewAddressModal,
    AddressTable,
  },
}
</script>

<style lang="scss">
.credit-card-list {
  #card-list tr:hover > td > .checkbox > span {
    border-color: #ffffff;
  }

  #card-list {
    .cursor-not-change {
      cursor: not-allowed;
    }
    .cursor-change {
      cursor: pointer;
    }
    .column-action {
      min-width: 115px;
    }
    td:last-child {
      width: 85px;
      padding: 0px;
    }
    .holder-name {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      padding-left: 7px;
    }
    .card-no {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #8a94a6;
      padding-top: 9px;
    }

    .operation-buttons {
      padding-top: 20px;
      a {
        width: 50%;
      }
    }
  }

  .verify-credit-card {
    display: flex;
    align-items: center;

    i {
      color: #f64e60;
      margin-left: 4px;
      cursor: pointer;
    }
  }

  #card-list table {
    min-height: 120px;
  }
}
</style>
