<template>
  <b-modal
    id="modal-redirect-login"
    centered
    hide-footer
    hide-header
    header-text-variant="light"
    hide-header-close
    size="md"
  >
    <b-container>
      <b-form class="form" @submit.stop.prevent="redirectLogin">
        <div class="d-flex flex-column">
          <p class="text-dark font-size-secondary mb-5 font-weight-bold">
            {{ $ml.get('beingRedirectedLoginScreen') }}
          </p>
          <p class="text-dark font-size-custom mb-5 font-weight-bold">
            {{ $ml.get('timeLeft') }}:
            <span class="text-primary"
              >{{ this.countDown }} {{ $ml.get('second') }}</span
            >
          </p>
          <!-- <p class="mb-5 text-custom-gray">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum fermentum nec elit sit amet blandit. Mauris condimentum mattis tortor eget auctor. Morbi rutrum magna risus, eu aliquam justo pellentesque sed.
          </p> -->
          <button
            class="btn btn-primary w-100 d-block px-9 py-3 rounded"
            ref="email_button"
          >
            {{ $ml.get('goToLogin') }}
          </button>
        </div>
      </b-form>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: 'RedirectLoginModal',
  props: { startCountDown: Boolean },
  data() {
    return {
      countDown: 15,
    }
  },
  created() {},
  methods: {
    countDownTimer() { 
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.redirectLogin()
      }
    },
    redirectLogin() {
      this.$router.push('login')
    },
  },
  watch: {
    startCountDown: function (n, o) {
      if (n === true) {
        this.countDownTimer()
      }
    },
  },
}
</script>

<style lang="scss">
#modal-redirect-login {
  .font-size-custom {
    font-size: 14px;
  }
}
</style>