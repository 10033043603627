<template>
  <div>
    <b-modal id="modal-dashboardconfirm-email"
             centered
             hide-footer
             hide-header
             header-text-variant="light"
             hide-header-close
             size="md">
      <b-container>
        <b-form class="form"
                @submit.stop.prevent="confirm">
          <div class="d-flex flex-column">
            <p class="text-dark font-size-secondary mb-5 font-weight-bold">
              {{ $ml.get('verifyYourEmailAddress') }}
            </p>
            <p class="mb-5 text-custom-gray">
              {{ $ml.get('confirmEmailInfoText') }}
            </p>
            <b-form-input v-model="$v.token.$model"
                          :state="validateState('token')"
                          :placeholder="$ml.get('confirmationCode')"
                          class="mb-5"></b-form-input>

            <p class="mb-5 text-custom-gray">
              {{ $ml.get('confirmEmailConfirmationCode') }}
              <u class="try-again"
                 role="button"
                 @click="sendEmailToken">
                {{ $ml.get('tryAgain') }}
                <div class="custom_spinner"
                     ref="email_try_again_button"></div>
              </u>
            </p>

            <button class="btn btn-primary w-100 d-block px-9 py-3 rounded"
                    ref="email_button">
              {{ $ml.get('confirm') }}
            </button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <RedirectLoginModal :startCountDown="startCountDown"></RedirectLoginModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import { VERIFY_REGISTERED_USER } from '@/core/services/store/auth.module'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import RedirectLoginModal from '@/components/RedirectLoginModal'

export default {
  name: 'DashboardConfirmEmailModal',
  mixins: [validationMixin],
  data() {
    return {
      token: '',
      startCountDown: false,
    }
  },
  validations: {
    token: {
      required,
      minLength: minLength(2),
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name]
      return $dirty ? !$error : null
    },
    sendEmailToken() {
      // set spinner to submit button
      const tryTokenCode = this.$refs['email_try_again_button']
      this.$spinnerLoader.addSpinnerLoader(tryTokenCode)

      ApiService.put('mail/send-verification-code', {
        email: this.currentUser.user.email,
      })
        .then((res) => {
          this.generateToast(
            'success',
            this.$ml.get('confirmationCodeHasBeenSentMail')
          )
          this.$spinnerLoader.removeSpinnerLoader(tryTokenCode)
        })
        .catch((err) => {
          console.log(err)
          this.$spinnerLoader.removeSpinnerLoader(tryTokenCode)

          if (err == 'Error') {
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          }
        })
    },
    confirm() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }

      // set spinner to submit button
      const submitButton = this.$refs['email_button']
      this.$spinnerLoader.addSpinnerLoader(submitButton)

      ApiService.put('mail/verify', {
        email: this.currentUser.user.email,
        token: this.token,
      })
        .then((res) => {
          this.$store
            .dispatch(VERIFY_REGISTERED_USER, this.currentUser.user.email)
            .then((res) => {
              if (res.is_email_validated) {
                this.generateToast('success', this.$ml.get('yourMailConfirmed'))
              } else {
                this.generateToast(
                  'danger',
                  this.$ml.get('yourMailConfirmError')
                )
              }

              this.$bvModal.hide('modal-dashboardconfirm-email')
              this.token = ''
              this.startCountDown = true
              this.$bvModal.show('modal-redirect-login')
              // setTimeout(() => {
              //   this.token = ''
              //   this.$router.push('login')
              // }, 500)
              this.$spinnerLoader.removeSpinnerLoader(submitButton)
            })
        })
        .catch((err) => {
          console.log(err)
          this.$spinnerLoader.removeSpinnerLoader(submitButton)

          if (err == 'Error') {
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          }
        })
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  components: {
    RedirectLoginModal,
  },
}
</script>

<style lang="scss">
#modal-dashboardconfirm-email {
  .text-custom-gray {
    color: #80808f;
  }
  .try-again {
    display: inline-flex;
    align-items: center;

    .custom_spinner {
      display: inline-block;
      width: 40px;
      height: 30px;

      &.spinner-light::before {
        border: 2px solid #181c32;
        border-right: 2px solid transparent;
      }
    }
  }
}
</style>