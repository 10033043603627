<template>
  <!--begin::DashboardConfirm-->
  <Card>
    <div class="row dashboard-confirm">
      <div class="col-md-12 mb-8 mb-lg-0"
           :class="isUserConfirmed?'col-lg-12':'col-lg-8'">
        <i class="icon-3x far fa-smile-wink text-primary mb-3"></i>
        <p class="mb-5 info-title">
          {{ $ml.get('welcomeText') }}, {{ currentUser.user.fullname }}
        </p>
        <p class="mb-3 info-text">{{ $ml.get(`welcomeContent${this.appName()}`) }}</p>
        <p class="mb-3 info-text">
          {{ $ml.get('hereIsOurNumber') }}
          <a href="tel:+908503218899">+90 850 321 88 99</a>
        </p>
        <p class="info-text">{{ $ml.get('niceWorkingDay') }}</p>
        <p class="info-text">{{ $ml.get('sincerely') }}</p>
      </div>

      <div class="col-md-12 col-lg-4 d-none d-lg-block"
           v-if="!isUserConfirmed">
        <CustomerAgent />
      </div>
    </div>

    <DashboardConfirmCards></DashboardConfirmCards>
  </Card>
  <!--end::DashboardConfirm-->
</template>

<script>
import { mapGetters } from 'vuex'
import Card from '@/components/Card'
import DashboardConfirmCards from '@/components/userVerifications/DashboardConfirmCards'
import CustomerAgent from '@/components/CustomerAgent'

export default {
  name: 'DashboardConfirmArea',
  components: {
    Card,
    DashboardConfirmCards,
    CustomerAgent,
  },
  computed: {
    ...mapGetters(['userVerification', 'currentUser', 'skipWelcome']),
    isUserConfirmed() {
      return this.userVerification.is_email_validated || this.skipWelcome
    },
  },
  methods: {
    appName() {
      return process.env.VUE_APP_NAME
    },
  },
}
</script>

<style lang="scss">
.dashboard-confirm {
  .agent {
    background-color: #00baf2;
    .agent-img {
      width: 65px;
      height: 65px;
    }
    .agent-cta-button {
      border-color: rgb(255, 255, 255) !important;
    }
  }
  .info-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: #212121;
  }
  .info-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;

    color: #212121;
  }
}
</style>
