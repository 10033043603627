<template>
  <!--begin::General-->
  <div>
    <!--begin::DashboardConfirm-->
    <div class="row mb-6"
         v-if="(!userVerification.is_email_validation_required == userVerification.is_email_validated ||
          !userVerification.is_cellphone_validation_required == userVerification.is_cellphone_validated ||
          !(userVerification.is_nin_validation_required == false || (userVerification.is_nin_validation_required && userVerification.nin_validated))) &&
          !skipWelcome
      ">
      <div class="col-md-12">
        <DashboardConfirmArea></DashboardConfirmArea>
      </div>
    </div>
    <!--end::DashboardConfirm-->
    <!-- <div v-show="isUserConfirmed"> -->
      <div v-else>
      <!--begin::InvoiceAlert-->
      <div class="row mb-6 mt-6 mt-lg-0"
           v-if="false">
        <div class="col-md-12">
          <InvoiceAlert title="Ödenmemiş Fatura Bildirimi"
                        :per="1"
                        price="2000,90"></InvoiceAlert>
        </div>
      </div>
      <!--end::InvoiceAlert-->

      <Loader></Loader>

      <!--begin::Banner and CustomerAgent-->
      <div class="row mb-6">
        <div class="col-12 mb-8 mb-lg-0">
          <Banners />
        </div>
      </div>
      <!--end::Banner and CustomerAgent-->

      <!--begin::UserCard and AccountType-->
      <div class="row mb-6"
           :disabled="!userVerification.is_email_validated">
        <div class="col-md-12 col-lg-6 mb-6 mb-lg-0">
          <UserCard></UserCard>
        </div>
        <div class="col-md-12 col-lg-6">
          <AccountType :accountTypes="accountTypes"></AccountType>
        </div>
      </div>
      <!--end::UserCard and AccountType-->

      <!--begin::GeneralSettings-->
      <div class="row mb-6"
           :disabled="!userVerification.is_email_validated">
        <div class="col-md-12">
          <GeneralSettings></GeneralSettings>
        </div>
      </div>
      <!--end::GeneralSettings-->

      <!--begin::AddressList-->
      <div class="row mb-6"
           :disabled="!userVerification.is_email_validated">
        <div class="col-md-12">
          <AddressList></AddressList>
        </div>
      </div>
      <!--end::AddressList-->

      <!--begin::ActivityHistory-->
      <div class="row"
           :disabled="!userVerification.is_email_validated">
        <div class="col-md-12">
          <ActivityHistory v-if="false"></ActivityHistory>
        </div>
      </div>
      <!--end::ActivityHistory-->
    </div>
  </div>
  <!--end::General-->
</template>

<script>
import ApiService from '@/core/services/api.service'
import JwtService from '@/core/services/jwt.service'
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import {
  GET_MY_USER,
  VERIFY_REGISTERED_USER,
  LOGOUT,
} from '@/core/services/store/auth.module'
import InvoiceAlert from '@/components/InvoiceAlert'
import UserCard from '@/components/UserCard'
import AccountType from '@/components/AccountType'
import GeneralSettings from '@/components/GeneralSettings'
import ActivityHistory from '@/components/ActivityHistory'
import Loader from '@/view/content/Loader.vue'
import { RUN_LOADER } from '@/core/services/store/htmlclass.module.js'
import DashboardConfirmArea from '@/components/userVerifications/DashboardConfirmArea'
import Banners from '@/components/general/Banners'
import AddressList from '@/components/invoice/AddressList'

export default {
  name: 'general',
  data() {
    return {
      user: {},
      accountTypes: [],
    }
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(RUN_LOADER, true)
  },
  mounted() {
    this.setBreadcrumb()

    this.$ml.bus.$on('vueml-language-changed', (payload) => {
      this.setBreadcrumb()
    })

    if (this.isUserConfirmed) {
      this.getMyUser()
      this.getAccountTypes()
    }
  },
  methods: {
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$ml.get('general'), route: 'general' },
      ])
    },
    getMyUser() {
      this.$store
        .dispatch(GET_MY_USER)
        .then((res) => {
          this.$store.dispatch(RUN_LOADER, false)
        })
        .catch((err) => {
          setTimeout(() => this.$router.push({ path: '/login' }), 1000)
        })
    },
    getAccountTypes() {
      ApiService.get('accounts/types').then((res) => {
        if (res) {
          this.accountTypes = res.data.data.filter((res) => {
            return res.description
              ? res.description
              : (res.description = res.name)
          })
        }
      })
    },
  },
  computed: {
    ...mapGetters(['userVerification', 'currentUser', 'skipWelcome']),
    isUserConfirmed() {
      return this.userVerification.is_email_validated || this.skipWelcome
    },
    isUserEmailConfirmed() {
      return this.userVerification.is_email_validated
    },
  },
  watch: {
    isUserConfirmed(newVal) {
      if (newVal) {
        this.getMyUser()
        this.getAccountTypes()
      }
    },
    isUserEmailConfirmed(newVal) {
      if (newVal) {
        const token = JwtService.getToken()

        if (token == 'Empty No Token') {
          this.$store.dispatch(LOGOUT)
          this.$router.push({ name: 'login' })
        }
      }
    },
  },
  components: {
    InvoiceAlert,
    UserCard,
    AccountType,
    GeneralSettings,
    ActivityHistory,
    Loader,
    DashboardConfirmArea,
    AddressList,
    Banners,
  },
}
</script>
