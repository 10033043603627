<template>
  <div class="teknosa-banner-collapse">
    <b-card no-body
            class="teknosa-slider card-custom text-white">
      <b-carousel id="carousel-fade"
                  indicators
                  img-width="950"
                  img-height="350">
        <b-carousel-slide v-for="(slide, index) in slides"
                          :key="index"
                          :img-src="slide.img">
          <div class="font-weight-bold title mb-2">
            {{ $ml.get(slide.title)+' : STASH ' }}
          </div>
          <div class="subtitle mb-5">
            {{ $ml.get(slide.subtitle) }}
          </div>
          <div class="d-flex">
            <button class="btn btn-custom"
                    v-b-modal.modal-teknosa-stash-banner>{{ $ml.get('subscribe') }}</button>
          </div>
          <div class="teknocacell">
            <img src="/image/teknosa-banner/teknosacell-modal.svg"
                 alt="teknocacell" />
          </div>
        </b-carousel-slide>
      </b-carousel>
    </b-card>

    <TeknosaStashModal @selectedCatalog="getSelectedCatalog"></TeknosaStashModal>
    <TeknosaStashPaymentModal :catalogName="selectedCatalog.name"
                              :catalogId="selectedCatalog.id"></TeknosaStashPaymentModal>
    <TeknosaStashFreeModal :catalogId="selectedCatalog.id"></TeknosaStashFreeModal>

    <UsageAgreementModal :catalogAgreement="selectedCatalog.agreement"></UsageAgreementModal>

  </div>
</template>

<script>
import Card from '@/components/Card'
import TeknosaStashModal from '@/components/general/TeknosaStashModal'
import TeknosaStashPaymentModal from '@/components/general/TeknosaStashPaymentModal'
import TeknosaStashFreeModal from '@/components/general/TeknosaStashFreeModal'
import UsageAgreementModal from '@/components/general/UsageAgreementModal'
import ApiService from '@/core/services/api.service'

export default {
  name: 'TeknosaBanner',
  components: {
    Card,
    TeknosaStashModal,
    TeknosaStashPaymentModal,
    TeknosaStashFreeModal,
    UsageAgreementModal,
  },
  data() {
    return {
      selectedCatalog: {},
      slides: [
        {
          img: '/image/teknosa-banner/teknosacell.png',
          title: 'freeCloudService',
          subtitle: 'teknosaBannerSubtitle',
        },
      ],
    }
  },
  methods: {
    getSelectedCatalog(catalog) {
      this.selectedCatalog = catalog
      if (catalog.price === 0) {
        this.subscribeApi(catalog.id)
      }
    },
    subscribeApi(catalogId) {
      ApiService.post(`marketplace/${catalogId}/subscribe`, {
        is_renewable: 1,
      })
        .then((res) => {
          this.generateToast('success', this.$ml.get('successful'))
        })
        .catch((err) => {
          if (err == 'Error') {
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          }
        })
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
  },
}
</script>
<style lang="scss">
.teknosa-banner-collapse {
  .teknosa-slider {
    min-height: 285px;
    position: relative;
    overflow: hidden;
    .carousel-indicators {
      right: unset;
      margin-left: 2rem;
      li {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 0;
      }
    }
    .carousel-item {
      img {
        min-height: 285px;
        object-fit: cover;
        object-position: right;
      }
      .carousel-caption {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 2rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-size: 24px;
          font-weight: 600 !important;
        }
        .subtitle {
          font-size: 16px;
          font-weight: 500 !important;
          padding-right: 180px;
        }
        .btn-custom {
          display: flex !important;
          background: #0058a9;
          color: #ffffff;
          z-index: 1;
          padding-right: 2.5rem !important;
          padding-left: 2.5rem !important;
        }
        .teknocacell {
          position: absolute;
          bottom: 10px;
          right: 10px;
          img {
            width: 100%;
            min-height: 0px;
            object-fit: cover;
            object-position: right;
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    .card {
      border-radius: 1rem;
      margin: 0 20px;
    }
  }
  @media (max-width: 767px) {
    .card {
      border-radius: 1rem;
      margin: 0 20px;
    }
    .teknosa-slider {
      .carousel-item {
        .carousel-caption {
          padding: 1.5rem;
          .title {
            font-size: 22px;
            font-weight: 600 !important;
          }
          .subtitle {
            font-size: 16px;
            font-weight: normal !important;
            padding-right: 0;
            padding-bottom: 10px;
            padding-top: 10px;
          }
          .btn-custom {
            background: #0058a9;
            color: #ffffff;
            z-index: 1;
            padding-right: 4rem !important;
            padding-left: 4rem !important;
          }
          .teknocacell {
            text-align: right;
            img {
              width: 80%;
            }
          }
        }
      }
    }
  }
}
</style>
