<template>
  <b-modal id="modal-generalsettings-email"
           centered
           hide-footer
           hide-header
           header-text-variant="light"
           hide-header-close
           size="m">
    <b-container>
      <div class="d-flex flex-column">
        <p class="text-dark font-size-secondary mb-5 font-weight-bold">
          {{ $ml.get('updateYourEmail') }}
        </p>

        <b-form class="form"
                @submit.stop.prevent="sendCode">
          <p class="mb-5">{{ $ml.get('pleaseEnterEmailAddress') }}</p>
          <div class="d-flex">
            <b-form-input v-model="$v.email.email.$model"
                          :state="validateState('email')"
                          :placeholder="$ml.get('emailAddress')"
                          class="mb-5"></b-form-input>
          </div>
          <button class="btn btn-primary w-100 d-block px-9 py-3 mb-5 rounded-sm"
                  ref="send_mail_code">
            {{ $ml.get('saveAndSendConfirmationCode') }}
          </button>
        </b-form>

        <!-- <p class="mb-5">
          {{ $ml.get('confirmEmailConfirmationCode') }}
          <u>{{ $ml.get('tryAgain') }}</u>
        </p> -->

        <b-form class="form"
                @submit.stop.prevent="confirm">
          <b-form-input v-model="$v.code.code.$model"
                        :placeholder="$ml.get('confirmationCode')"
                        :state="validateState('code')"
                        class="mb-5"></b-form-input>
          <button class="btn btn-primary w-100 d-block px-9 py-3 rounded-sm"
                  ref="confirm_code">
            {{ $ml.get('confirm') }}
          </button>
        </b-form>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import { VERIFY_REGISTERED_USER } from '@/core/services/store/auth.module'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'GeneralSettingsEmailModal',
  mixins: [validationMixin],
  data() {
    return {
      code: {
        code: '',
      },
      email: {
        email: '',
      },
    }
  },
  validations: {
    email: {
      email: {
        required,
        email,
        minLength: minLength(2),
      },
    },
    code: {
      code: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } =
        name == 'code' ? this.$v.code[name] : this.$v.email[name]
      return $dirty ? !$error : null
    },
    sendCode() {
      this.$v.email.$touch()
      if (this.$v.email.$anyError) {
        return
      }

      // set spinner to submit button
      const sendEmailCode = this.$refs['send_mail_code']
      this.$spinnerLoader.addSpinnerLoader(sendEmailCode)

      ApiService.put('users/change-email', {
        email: this.email.email,
      })
        .then((res) => {
          this.$generateToast(
            this,
            'success',
            this.$ml.get('confirmationCodeHasBeenSentMail')
          )
          this.$spinnerLoader.removeSpinnerLoader(sendEmailCode)
        })
        .catch((err) => {
          console.log(err)
          this.$spinnerLoader.removeSpinnerLoader(sendEmailCode)

          if (err == 'Error') {
            this.$generateToast(
              this,
              'danger',
              this.$ml.get('anErrorHasOccurred')
            )
          }
        })
    },
    confirm() {
      this.$v.code.$touch()
      if (this.$v.code.$anyError) {
        return
      }

      // set spinner to submit button
      const confirmCode = this.$refs['confirm_code']
      this.$spinnerLoader.addSpinnerLoader(confirmCode)

      ApiService.put(
        `mail/verify?email=${this.email.email}&token=${this.code.code}`
      )
        .then((res) => {
          this.$store
            .dispatch(VERIFY_REGISTERED_USER, this.email.email)
            .then((res) => {
              if (res.is_email_validated) {
                this.$generateToast(
                  this,
                  'success',
                  this.$ml.get('yourMailConfirmed')
                )
                setTimeout(() => this.$router.push({ name: 'login' }), 1000)
              } else {
                this.$generateToast(
                  this,
                  'danger',
                  this.$ml.get('yourMailConfirmError')
                )
                this.$bvModal.hide('modal-generalsettings-email')
              }
              this.$spinnerLoader.removeSpinnerLoader(confirmCode)
            })
        })
        .catch((err) => {
          console.log(err)
          this.$spinnerLoader.removeSpinnerLoader(confirmCode)

          if (err == 'Error') {
            this.$generateToast(
              this,
              'danger',
              this.$ml.get('anErrorHasOccurred')
            )
          }
        })
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
}
</script>
