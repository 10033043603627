<template>
  <div class="confirm-cards-collapse">
    <div class="confirm-cards border-bottom border-secondary pb-5 mb-5 border-top border-secondary pt-5 mt-5">

      <Card class="bg-alert mb-5">
        <div class="invoice-alert d-flex flex-column flex-md-row">
          <div class="d-flex align-items-center flex-md-fill mb-3 mb-md-0">
            <GroupChatIcon color="F64E60"
                           class="mr-3" />
            <div class="alert-text">
              {{ $ml.get('welcomeExperienceText') }}
            </div>
          </div>
        </div>
      </Card>

      <notTurkishCitizen />

      <div class="d-flex justify-content-between cards-collapse">
        <div class="cards col rounded-sm p-5 mr-5 text-center"
             :class="getCardClass(userVerification.is_email_validated)">
          <i class="icon-bg-envelope icon-2x card-icon fas fa-envelope text-success p-3 rounded-sm"></i>
          <p class="text-dark font-weight-bold mt-3 mb-5 card-text">
            {{ $ml.get('mailVerification') }}
          </p>
          <div v-if="userVerification.is_email_validated"
               class="card-button">
            <i class="icon-3x fa fa-check text-primary mb-3"></i>
          </div>
          <div v-else
               class="card-button">
            <b-button variant="primary"
                      class="rounded btn"
                      v-b-modal.modal-dashboardconfirm-email>{{ $ml.get('mailVerification') }}</b-button>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="cards col rounded-sm p-5 mr-5 text-center"
             :disabled="!userVerification.is_email_validated"
             :class="getCardClass(userVerification.is_cellphone_validated)">
          <i class="icon-bg-phone icon-2x card-icon fas fa-mobile text-danger p-3 rounded-sm"></i>
          <p class="text-dark font-weight-bold mt-3 mb-5 card-text">
            {{ $ml.get('gsmVerification') }}
          </p>
          <div v-if="userVerification.is_cellphone_validated"
               class="card-button">
            <i class="icon-3x fa fa-check text-primary mb-3"></i>
          </div>
          <div v-else
               class="card-button">
            <b-button variant="primary"
                      class="rounded btn"
                      v-b-modal.modal-dashboardconfirm-phone>{{ $ml.get('gsmVerification') }}</b-button>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="cards col rounded-sm p-5 text-center"
             v-if="userVerification.is_nin_validation_required"
             :disabled="!userVerification.is_email_validated"
             :class="getCardClass(userVerification.nin_validated)">
          <i class="icon-bg-identity icon-2x card-icon fas fa-id-card text-primary p-3 rounded-sm"></i>
          <p class="text-dark font-weight-bold mt-3 mb-5 card-text">
            {{ $ml.get('identityVerification') }}
          </p>
          <div v-if="userVerification.nin_validated"
               class="card-button">
            <i class="icon-3x fa fa-check text-primary mb-3"></i>
          </div>
          <div v-else
               class="card-button">
            <b-button variant="primary"
                      class="rounded btn"
                      v-b-modal.modal-dashboardconfirm-identity>{{ $ml.get('identityVerification') }}</b-button>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between skip-steps">
      <div class="d-flex">
        <p class="text-muted">
          {{ $ml.get('welcomePermissionText') }}
        </p>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="primary"
                  class="rounded btn btn-primary px-10"
                  v-b-modal.modal-dashboardconfirm-skip>{{ $ml.get('skipSteps') }}</b-button>
      </div>
    </div>

    <DashboardConfirmEmailModal></DashboardConfirmEmailModal>
    <DashboardConfirmPhoneModal></DashboardConfirmPhoneModal>
    <DashboardConfirmIdentityModal></DashboardConfirmIdentityModal>
    <DashboardConfirmSkipModal></DashboardConfirmSkipModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardConfirmEmailModal from '@/components/userVerifications/DashboardConfirmEmailModal'
import DashboardConfirmPhoneModal from '@/components/userVerifications/DashboardConfirmPhoneModal'
import DashboardConfirmIdentityModal from '@/components/userVerifications/DashboardConfirmIdentityModal'
import DashboardConfirmSkipModal from '@/components/userVerifications/DashboardConfirmSkipModal'
import notTurkishCitizen from '@/components/userVerifications/notTurkishCitizen'
import GroupChatIcon from '@/components/icons/GroupChat'
import Card from '@/components/Card'

export default {
  name: 'DashboardConfirmCards',
  computed: {
    ...mapGetters(['userVerification']),
  },
  created() {
    this.controlMailValidated()
  },
  methods: {
    getCardClass(isCompleted) {
      return isCompleted
        ? 'border border-primary'
        : 'border-dashed border-secondary'
    },
    controlMailValidated() {
      if (!this.userVerification.is_email_validated) {
        setTimeout(
          () => this.$bvModal.show('modal-dashboardconfirm-email'),
          500
        )
      }
    },
  },
  components: {
    DashboardConfirmEmailModal,
    DashboardConfirmPhoneModal,
    DashboardConfirmIdentityModal,
    DashboardConfirmSkipModal,
    GroupChatIcon,
    Card,
    notTurkishCitizen,
  },
}
</script>

<style lang="scss">
.confirm-cards-collapse {
  .confirm-cards {
    .border-dashed {
      border-style: dashed;
    }
    .icon-bg-envelope {
      background-color: #c9f7f5;
    }
    .icon-bg-phone {
      background-color: #ffe2e5;
    }
    .icon-bg-identity {
      background-color: #e1f0ff;
    }
    .bg-alert {
      .card-body {
        padding: 1rem 1.5rem !important;
        .alert-text {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #464e5f;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .confirm-cards {
      .cards-collapse {
        display: block !important;
        .cards {
          margin-bottom: 20px;
          .card-icon {
            float: left;
            width: 50px;
          }
          .card-text {
            margin-left: 15px;
            float: left;
          }
          .card-button {
            float: right;
          }
        }
        .cards:last-child {
          margin-bottom: 5px;
        }
      }
    }
    .skip-steps {
      display: block !important;
      button {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}
</style>
