<template>
  <div id="modal-address-detail"
       v-show="showOffcanvas">
    <div id="add-new-address-detail"
         :class="['offcanvas offcanvas-right offcanvas-custom', { 'offcanvas-on' : show, 'offcanvas-off' : !show }]">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between p-5">
        <h3 class="font-weight-bold m-0">
          {{ $ml.get(isEdit ? 'updateAddress':'addNewAddress') }}
        </h3>
        <a href="javascript:;"
           class="d-flex btn btn-xs btn-light p-2 rounded-sm sidebar_close"
           @click="close">
          <i class="far fa-times-circle p-0"></i>
        </a>
      </div>
      <!--end::Header-->

      <div class="offcanvas-content pt-10 px-5">
        <div class="d-flex flex-column"
             :disabled="loading">

          <div class="form">
            <div class="col-md-12">
              <b-form-group id="nameGroup"
                            :label="`${$ml.get('addressName')}:`"
                            label-for="addressName">
                <b-form-input id="addressName"
                              v-model="address.name"
                              required
                              :placeholder="$ml.get('addressName')"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-12">
              <b-form-group id="nameGroup"
                            :label="`${$ml.get('addressLine1')}:`"
                            label-for="addressLine1">
                <b-form-input id="addressLine1"
                              v-model="address.line1"
                              required
                              :placeholder="$ml.get('addressLine1')"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-12">
              <b-form-group id="nameGroup"
                            :label="`${$ml.get('addressLine2')}:`"
                            label-for="addressLine2">
                <b-form-input id="addressLine2"
                              v-model="address.line2"
                              required
                              :placeholder="$ml.get('addressLine2')"></b-form-input>
              </b-form-group>
            </div>

            <div class="row mr-0 ml-0">
              <div class="col-md-6">
                <b-form-group id="nameGroup"
                              :label="`${$ml.get('city')}:`"
                              label-for="city">
                  <b-form-input id="city"
                                v-model="address.city"
                                required
                                :placeholder="$ml.get('city')"></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group id="nameGroup"
                              :label="`${$ml.get('state')}:`"
                              label-for="state">
                  <b-form-input id="state"
                                v-model="address.state"
                                required
                                :placeholder="$ml.get('state')"></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="col-md-12">
              <b-form-group id="nameGroup"
                            :label="`${$ml.get('postCode')}:`"
                            label-for="postCode">
                <b-form-input id="postCode"
                              v-model="address.postcode"
                              required
                              :placeholder="$ml.get('postCode')"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-12">
              <b-form-group id="countryGroup"
                            :label="$ml.get('country')"
                            label-for="country">
                <multiselect id="country"
                             v-model="address.country_code"
                             :options="countries"
                             :allowEmpty="false"
                             :show-labels="false"
                             :searchable="true"
                             :placeholder="$ml.get('pleaseSelect')"
                             :custom-label="customCountryLabel"
                             required>
                </multiselect>
              </b-form-group>
            </div>

            <div class="col-md-12">
              <b-form-group id="emailGroup"
                            :label="`${$ml.get('emailAddress')}:`"
                            label-for="email_address">
                <b-form-input id="email_address"
                              v-model="address.email_address"
                              required
                              :placeholder="$ml.get('emailAddress')"></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="buttons">
            <b-button type="reset"
                      class="px-15 mr-3"
                      @click="close"
                      variant="danger">{{$ml.get('cancel')}}</b-button>

            <b-button type="submit"
                      class="px-15"
                      ref="submitButton"
                      @click="confirm"
                      variant="primary">{{$ml.get('save')}}</b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="offcanvas-overlay"
         v-if="show"
         @click="close"></div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'

import { ContentLoader } from 'vue-content-loader'
import EventBus from '@/event-bus'

export default {
  name: 'AddNewAddressModal',
  props: {
    items: Object | Array,
    show: Boolean,
  },
  data() {
    return {
      showOffcanvas: false,
      isEdit: false,
      countries: [],
      address: {
        name: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        postcode: '',
        country_code: '',
        email_address: '',
      },
      loading: false,
    }
  },
  created() {
    this.setShowOffcanvas()
    this.getCountries()
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    setShowOffcanvas() {
      setTimeout(() => {
        this.showOffcanvas = true
      }, 1000)
    },
    async confirm() {
      // api service type
      let type = this.isEdit ? 'put' : 'post'
      // api url
      // let url = `users/${this.currentUser.user.id}/addresses${
      let url = `accounts/addresses${this.isEdit ? `/${this.items.id}` : ''}`
      // api params
      let params = {
        name: this.address.name,
        line1: this.address.line1,
        line2: this.address.line2,
        city: this.address.city,
        state: this.address.state,
        postcode: this.address.postcode,
        country_code: this.address.country_code.code,
        email_address: this.address.email_address,
      }

      this.loading = true

      try {
        await ApiService[type](url, params)
        this.$generateToast(
          this,
          'success',
          this.isEdit
            ? this.$ml.get('updatedAddressSuccess')
            : this.$ml.get('addedAddressSuccess')
        )

        EventBus.$emit('addAddressSuccess', true)
        this.close()
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$generateToast(this, 'danger', this.$ml.get('anErrorHasOccurred'))
      }
    },
    async getCountries() {
      let res = await ApiService.get('countries')
      this.countries = res.data.data
    },
    customCountryLabel({ name }) {
      return name
    },
    close() {
      // clear address model
      this.address = {
        name: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        postcode: '',
        country_code: '',
        email_address: '',
      }

      this.$emit('closeModal', false)
    },
  },
  watch: {
    items() {
      this.isEdit = this.items ? true : false

      if (this.items) {
        this.address = { ...this.items } // {...} not reference model

        // country_code in address model
        if (this.address.country && this.address.country.data) {
          this.address.country_code = {
            code: this.address.country.data.code,
            name: this.address.country.data.name,
          }
        }
      }
    },
  },
  components: {
    ContentLoader,
  },
}
</script>

<style lang="scss">
#modal-address-detail {
  header {
    justify-content: center;
  }

  .title {
    text-align: center;
    font-family: Poppins;

    h3 {
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
      color: #212121;
    }

    a {
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;

      .phone {
        color: #b5b5c3;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .buttons {
    text-align: center;
  }
  td:first-child {
    font-weight: bold;
  }
  td:last-child {
    text-align: right;
  }
}

#add-new-address-detail {
  overflow: hidden;

  .sidebar_close {
    i {
      color: #f64e60 !important;
      font-size: 15px;
    }
  }
}
</style>
