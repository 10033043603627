<template>
  <div>
    <b-card v-if="loading">
      <CardLoader>
        <rect x="0"
              y="4"
              width="400"
              height="12"
              rx="3" />
        <rect x="0"
              y="24"
              width="150"
              height="12"
              rx="3" />
        <rect x="0"
              y="56"
              width="400"
              height="12"
              rx="3" />
        <rect x="0"
              y="76"
              width="370"
              height="12"
              rx="3" />
        <rect x="0"
              y="96"
              width="178"
              height="12"
              rx="3" />
      </CardLoader>
    </b-card>
    <div class="row"
         v-else>
      <div :class="{'col-md-12 col-lg-8 mb-8 mb-lg-0' : showBannerSlug != '', 'd-none' : showBannerSlug == ''}">
        <TeknosaBanner v-if="showBannerSlug=='plusclouds-stash'" />
        <LeoBanner v-if="showBannerSlug=='plusclouds-leo-one'" />
      </div>
      <div :class="{'col-md-12 col-lg-4 d-none d-lg-block' : showBannerSlug != '', 'col-12' : showBannerSlug == ''}">
        <CustomerAgent :showAsCard="showBannerSlug!=''"></CustomerAgent>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import CardLoader from '@/view/content/CardLoader'
import TeknosaBanner from '@/components/general/TeknosaBanner'
import LeoBanner from '@/components/general/LeoBanner'
import CustomerAgent from '@/components/CustomerAgent'

export default {
  name: 'Banners',
  data() {
    return {
      loading: false,
      showBannerSlug: '',
    }
  },
  created() {
    this.getCampaignBannerList()
  },
  methods: {
    getCampaignBannerList() {
      this.getBanners()
        .then((res) => {
          this.setBanners(res)
        })
        .catch((err) => {
          this.showBannerSlug = ''
        })
    },
    getBanners() {
      this.loading = true
      return new Promise((resolve, reject) => {
        ApiService.get(`marketplace?hasCampaign=true`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            this.loading = false
            reject(err)
          })
      })
    },
    setBanners(res) {
      let filtered = res.data.filter((f) => {
        return (
          f.is_campaign_available == true &&
          (f.slug == 'plusclouds-stash' || f.slug == 'plusclouds-leo-one')
        )
      })
      if (filtered.length == 0) {
        this.showBannerSlug = ''
      } else if (filtered.length == 1) {
        this.showBannerSlug = filtered[0].slug
      } //lenght>1
      else {
        this.showBannerSlug =
          filtered[Math.floor(Math.random() * filtered.length)].slug
      }

      this.loading = false
    },
  },
  watch: {
    showBannerSlug: function () {
      if (
        this.showBannerSlug != 'plusclouds-stash' &&
        this.showBannerSlug != 'plusclouds-leo-one'
      ) {
        this.showBannerSlug = ''
      }
    },
  },
  components: {
    CardLoader,
    TeknosaBanner,
    LeoBanner,
    CustomerAgent,
  },
}
</script> 