<template>
  <b-modal id="modal-dashboardconfirm-identity"
           centered
           hide-footer
           hide-header
           header-text-variant="light"
           hide-header-close
           size="m">
    <b-container>
      <b-form class="form"
              @submit.stop.prevent="confirm">
        <div class="d-flex flex-column">
          <p class="text-dark font-size-secondary mb-5 font-weight-bold">{{ $ml.get('verifyYourIdentityNumber') }}</p>
          <p class="mb-5 text-custom-gray">{{ $ml.get('verifyYourIdentityNumberInfoText') }}</p>
          <b-form-group id="fullname-group"
                        label-for="fullname-input"
                        class="mb-5">
            <b-form-input id="fullname-input"
                          name="fullname"
                          v-model.trim="$v.form.fullname.$model"
                          :state="validateState('fullname')"
                          aria-describedby="fullname-input-live-feedback"
                          :placeholder="$ml.get('fullname')"></b-form-input>

            <b-form-invalid-feedback id="fullname-input-live-feedback">
              {{ $ml.get('fullnameIsRequired') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <DateTime placeholder="birthDateRequiredFormatPlaceholder"
                    v-model="form.birthdate"
                    valueFormat="YYYY-MM-DD"
                    :langVal="$ml.current"
                    :state="validateState('birthdate')"
                    class="mb-5"></DateTime>

          <!-- <b-form-group id="birthDateGroup"
                        label-for="birthDate"
                        class="mb-5">
            <b-form-input id="birthDate"
                          v-model="form.birthdate"
                          v-mask="['##/##/####']"
                          :state="validateState('birthdate')"
                          :placeholder="$ml.get('birthDateRequiredFormatPlaceholder')"></b-form-input>
            <b-form-invalid-feedback id="birthDate-input-live-feedback">
              {{ $ml.get('birthDateIsRequired') }}
            </b-form-invalid-feedback>
          </b-form-group> -->

          <b-form-group id="identity-group"
                        label-for="identity-input"
                        class="mb-5">
            <b-form-input v-model="$v.form.identity.$model"
                          :state="validateState('identity')"
                          :placeholder="$ml.get('identityNumber')"></b-form-input>

            <b-form-invalid-feedback id="identity-input-live-feedback">
              {{ $ml.get('identityIsRequired') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <p class="mb-5 text-custom-gray">* {{$ml.get('birthDateRequiredFormat')}}</p>
          <button class="btn btn-primary w-100 d-block px-9 py-3 rounded"
                  ref="confirm_button">{{ $ml.get('confirm') }}</button>
        </div>
      </b-form>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { VERIFY_REGISTERED_USER } from '@/core/services/store/auth.module'
import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'DashboardConfirmIdentityModal',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        fullname: '',
        identity: '',
        birthdate: '',
      },
    }
  },
  validations: {
    form: {
      identity: {
        required,
        minLength: minLength(11),
      },
      birthdate: {
        required,
        // minLength: minLength(10),
        // birthdate(birthdate) {
        //   return (
        //     moment(birthdate, 'MM-DD-YYYY').format('YYYY-MM-DD') !=
        //     'Invalid date'
        //   )
        // },
      },
      fullname: {
        required,
        minLength: minLength(3),
        isFullname(fullname) {
          return /^[a-zA-Z_ğüşıöçĞÜŞİÖÇ]+(?:\s[a-zA-Z_ğüşıöçĞÜŞİÖÇ]+)+$/gm.test(
            fullname
          ) // check full name
        },
      },
    },
  },
  computed: {
    ...mapGetters(['registeredUser', 'currentUser', 'myUser']),
  },
  created() {
    //this.controlUserBirthdate()
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    controlUserBirthdate() {
      ApiService.get(`users/me?fields=birthday`)
        .then((res) => {
          // birthday success
        })
        .catch((err) => {
          console.log(err)
        })
    },
    confirm() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }

      // set spinner to submit button
      const confirmButton = this.$refs['confirm_button']
      this.$spinnerLoader.addSpinnerLoader(confirmButton)

      // if (
      //   moment(this.form.birthdate, 'MM-DD-YYYY').format('YYYY-MM-DD') ===
      //   'Invalid date'
      // ) {
      //   this.generateToast('danger', this.$ml.get('invalidBirthDate'))
      //   this.$spinnerLoader.removeSpinnerLoader(confirmButton)
      // } else {
      this.userBirthdateUpdate()
        .then((res) => {
          this.userIdentityUpdate()
        })
        .catch((err) => {
          this.generateToast('danger', this.$ml.get('birthDataUpdateError'))
          this.$spinnerLoader.removeSpinnerLoader(confirmButton)
        })
      // }
    },
    userBirthdateUpdate() {
      let name = this.form.fullname.split(' ').slice(0, -1).join(' ')
      let surname = this.form.fullname.split(' ').slice(-1).join(' ')

      return new Promise((resolve, reject) => {
        // ApiService.put(`users/${this.currentUser.user.id}`, {
        ApiService.put(`users/me`, {
          name: name,
          surname: surname,
          birthday: this.form.birthdate,
          // birthday: moment(this.form.birthdate, 'MM-DD-YYYY').format(
          //   'YYYY-MM-DD'
          // ),
        })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    userIdentityUpdate() {
      const confirmButton = this.$refs['confirm_button']
      ApiService.put(`users/me/nin/verify`, {
        citizen: 1,
        nin: this.form.identity,
      })
        .then((res) => {
          this.$store
            .dispatch(VERIFY_REGISTERED_USER, this.currentUser.user.email)
            .then((res) => {
              if (res.nin_validated) {
                this.generateToast(
                  'success',
                  this.$ml.get('identityNumberConfirmed')
                )
              } else {
                this.generateToast(
                  'danger',
                  this.$ml.get('identityNumberConfirmError')
                )
              }

              this.$bvModal.hide('modal-dashboardconfirm-identity')
              this.$spinnerLoader.removeSpinnerLoader(confirmButton)
            })
        })
        .catch((err) => {
          console.log(err)
          this.$spinnerLoader.removeSpinnerLoader(confirmButton)

          if (err == 'Error') {
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          }
        })
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
  },
}
</script>


<style lang="scss">
#modal-dashboardconfirm-identity {
  .text-custom-gray {
    color: #80808f;
  }
}
</style>