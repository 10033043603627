<template>
  <div class="gift-banner">
    <svg class="mb-2"
         width="24px"
         height="24px"
         viewBox="0 0 24 24"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs></defs>
      <g id="Stockholm-icons-/-Shopping-/-Gift"
         stroke="none"
         stroke-width="1"
         fill="none"
         fill-rule="evenodd">
        <rect id="bound"
              x="0"
              y="0"
              width="24"
              height="24"></rect>
        <path d="M4,6 L20,6 C20.5522847,6 21,6.44771525 21,7 L21,8 C21,8.55228475 20.5522847,9 20,9 L4,9 C3.44771525,9 3,8.55228475 3,8 L3,7 C3,6.44771525 3.44771525,6 4,6 Z M5,11 L10,11 C10.5522847,11 11,11.4477153 11,12 L11,19 C11,19.5522847 10.5522847,20 10,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,12 C4,11.4477153 4.44771525,11 5,11 Z M14,11 L19,11 C19.5522847,11 20,11.4477153 20,12 L20,19 C20,19.5522847 19.5522847,20 19,20 L14,20 C13.4477153,20 13,19.5522847 13,19 L13,12 C13,11.4477153 13.4477153,11 14,11 Z"
              id="Combined-Shape"
              fill="#ffffff"></path>
        <path d="M14.4452998,2.16794971 C14.9048285,1.86159725 15.5256978,1.98577112 15.8320503,2.4452998 C16.1384028,2.90482849 16.0142289,3.52569784 15.5547002,3.83205029 L12,6.20185043 L8.4452998,3.83205029 C7.98577112,3.52569784 7.86159725,2.90482849 8.16794971,2.4452998 C8.47430216,1.98577112 9.09517151,1.86159725 9.5547002,2.16794971 L12,3.79814957 L14.4452998,2.16794971 Z"
              id="Path-31"
              fill="#ffffff"
              fill-rule="nonzero"
              opacity="0.3"></path>
      </g>
    </svg>
    <p>
      {{ $ml.get('teknosaGiftBannerText') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'TeknosaGiftBanner',
}
</script>

<style lang="scss">
.gift-banner {
  background-color: #f58220;
  padding: 20px 60px;
  text-align: center;
  color: #ededed;
  border-radius: 6px;
  margin-bottom: 20px;

  &.max-555 {
    max-width: 555px;
  }

  svg {
    width: 37px;
    height: 37px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ededed;
  }
}

@media (max-width: 767px) {
  .gift-banner {
    padding: 10px !important;
    p {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
}
</style>
