<template>
  <div>
    <b-modal id="modal-paymentResult"
             centered
             hide-footer
             hide-header
             header-text-variant="light"
             hide-header-close
             body-bg-variant="success"
             body-text-variant="white"
             size="m">
      <b-container>
        <div class="d-flex flex-column">
          <p class="text-white font-size-secondary mb-5 font-weight-bold">
            {{ $ml.get('successful') }}
          </p>
          <p class="mb-5"
             v-html="this.replaceUrl($ml.get('teknosaPaymentSuccessMessage'))"></p>
          <b-button variant="white"
                    class="rounded-sm text-sucess"
                    @click="onCloseCompleteModal()">{{ $ml.get('okey') }}</b-button>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>


<script>
export default {
  name: 'TeknosaStashPaymentSuccessModal',
  methods: {
    onCloseCompleteModal() {
      this.$bvModal.hide('modal-paymentResult')
    },
    replaceUrl(text) {
      return text.replace(
        '{0}',
        '<a href="https://stash.plusclouds.com/teknosa/" target="_blank">https://stash.plusclouds.com/teknosa/</a>'
      )
    },
  },
}
</script>  

<style lang="scss">
#modal-paymentResult {
  .modal-body {
    border-radius: 14px;
  }
}
</style>