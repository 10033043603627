<template>
  <Card>
    <template #header>
      <div class="title pt-5 flex-fill">
        <h3 class="text-dark font-size-secondary m-0">Activity History</h3>
        <p class="font-size-md text-muted mt-2">The latest activity history on your account is displayed below!</p>
      </div>

      <ButtonLightDanger class="align-self-end justify-self-end "
                         href="#">
        <i class="fas fa-trash"></i>
        Clear History Data
      </ButtonLightDanger>
    </template>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-muted text-left pl-0">Last Opened By You</th>
            <th class="text-muted text-left">Access Point</th>
            <th class="text-muted text-left">IP Address</th>
            <th class="text-muted text-left">Country</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items"
              :key="item.id">
            <td class="font-weight-bold pl-0">{{ item.lastOpened }}</td>
            <td class="font-weight-bold">{{ item.accessPoint }}</td>
            <td class="font-weight-bold">{{ item.ip_addr }}</td>
            <td class="font-weight-bold">{{ item.country }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </Card>
</template>

<script>
import Card from '@/components/Card'
import ButtonLightDanger from '@/components/buttons/ButtonLightDanger'

export default {
  name: 'ActivityHistory',
  data() {
    return {
      items: [
        {
          id: 1,
          lastOpened: 'Tue, 08/26/2018 @ 6:22:34 am UTC',
          accessPoint: 'Safari',
          ip_addr: '000.000.00.00',
          country: 'Turkey',
        },
        {
          id: 2,
          lastOpened: 'Tue, 08/26/2018 @ 6:22:34 am UTC',
          accessPoint: 'Mozilla',
          ip_addr: '000.000.00.00',
          country: 'Spain',
        },
        {
          id: 3,
          lastOpened: 'Tue, 08/26/2018 @ 6:22:34 am UTC',
          accessPoint: 'Chrome',
          ip_addr: '000.000.00.00',
          country: 'Germany',
        },
        {
          id: 4,
          lastOpened: 'Tue, 08/26/2018 @ 6:22:34 am UTC',
          accessPoint: 'Chrome',
          ip_addr: '000.000.00.00',
          country: 'Turkey',
        },
      ],
    }
  },
  components: {
    Card,
    ButtonLightDanger,
  },
}
</script>
