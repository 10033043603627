<template>
  <a class="btn d-flex align-items-center justify-content-center text-bold px-5">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'SocialButton',
}
</script>