<template>
  <Card class="leo-banner">
    <div class="row">
      <div class="col-md-5 m-0 p-0 d-none d-md-flex align-items-center leo-image">
        <img src="/image/leo-banner-logo.svg"
             alt="leo"
             class="img-fluid w-100">
      </div>
      <div class="col-md-7">
        <div class="d-flex flex-row align-items-center">
          <div class="d-flex flex-column pt-10 content-area">
            <div class="font-weight-bold text-dark title mb-2">{{$ml.get('freeProxyNatDNSService')}}</div>
            <div class="subtitle mb-5">
              {{$ml.get('freeProxyNatDNSServiceInfo')}}
              <div class="mt-3"><i>{{$ml.get('freeProxyNatDNSServiceInfoEndDate')}}</i></div>
            </div>
            <div class="d-flex pt-3">
              <a class="d-flex btn btn-primary pr-10 pl-10"
                 href="https://leo.plusclouds.com/#virtual-machines?campaign=freevirtualmachine">
                {{$ml.get('joinTheCampaign')}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Card>
</template>

<script>
import Card from '@/components/Card'

export default {
  name: 'LeoBanner',
  components: {
    Card,
  },
}
</script>
<style lang="scss" scoped>
.leo-banner {
  background-color: #c9f7f5;
  min-height: 270px;
  .leo-image {
    padding-right: 50px !important;
  }
  .title {
    font-size: 24px;
    font-weight: 600 !important;
  }
  .subtitle {
    color: #80808f;
    font-size: 16px;
    font-weight: 500 !important;
    line-height: 24px;
    // padding-right: 75px;
    i {
      padding-top: 10px;
      font-size: 12px;
      line-height: 18px;
      color: #80808f;
    }
  }
}
</style>