<template>
  <div class="notTurkish rounded-sm p-5 mb-5 border-dashed border-secondary">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </template>

      <b-form class="form"
              @submit.stop.prevent="confirm">

        <div class="d-flex flex-column">
          <!--begin::Checkbox-->
          <label class="checkbox checkbox-lg checkbox-single flex-shrink-0 mb-5">
            <input type="checkbox"
                   id="notTurkishCitizen"
                   v-model="notTurkishCitizen"
                   aria-describedby="notTurkishCitizen-input-live-feedback" />
            <span class="mr-3"></span>
            <div class="text-dark font-weight-bold card-text">{{$ml.get('notTurkishCitizen')}}</div>
          </label>
          <!--end::Checkbox-->

          <b-form-group id="countryAuthGroup"
                        :label="$ml.get('country')"
                        label-for="countryAuth"
                        v-if="notTurkishCitizen">
            <multiselect id="countryAuth"
                         v-model="selectedCountry"
                         :options="countries"
                         :allowEmpty="false"
                         :show-labels="false"
                         :searchable="true"
                         :placeholder="$ml.get('pleaseSelect')"
                         :custom-label="customCountryLabel"
                         required>
            </multiselect>
          </b-form-group>

          <button class="btn btn-primary w-100 d-block px-9 py-3 rounded"
                  ref="confirm_button"
                  v-if="showConfirmButton">
            {{ $ml.get('confirm') }}
          </button>
        </div>
      </b-form>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
// Utils
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { GET_MY_USER } from '@/core/services/store/auth.module'
import { RUN_LOADER } from '@/core/services/store/htmlclass.module.js'
import { VERIFY_REGISTERED_USER } from '@/core/services/store/auth.module'

export default {
  name: 'notTurkishCitizen',
  mixins: [validationMixin],
  data() {
    return {
      notTurkishCitizen: '',
      selectedCountry: '',
      countries: [],
    }
  },
  validations: {
    selectedCountry: {
      required: requiredIf(function () {
        return this.notTurkishCitizen
      }),
    },
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(RUN_LOADER, true)
  },
  mounted() {
    this.getMyUser()
  },
  computed: {
    ...mapGetters(['myUser', 'isLoading']),
    showConfirmButton() {
      if (
        !this.selectedCountry ||
        !this.selectedCountry.code ||
        (this.myUser &&
          this.selectedCountry &&
          this.myUser.country_code == this.selectedCountry.code)
      ) {
        return false
      }

      return true
    },
  },
  created() {},
  methods: {
    getMyUser() {
      this.$store.dispatch(GET_MY_USER).then((res) => {
        this.$store.dispatch(RUN_LOADER, false)
        if (res && res.data) {
          this.notTurkishCitizen = res.data.country_code != 'TR'

          this.getCountries()
            .then((res) => {
              this.setCountries(res)
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    },
    getCountries() {
      return new Promise((resolve, reject) => {
        ApiService.get('countries')
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    setCountries(res) {
      this.countries = res.data

      if (this.myUser.country_code)
        this.selectedCountry = this.countries.filter(
          (f) => f.code == this.myUser.country_code
        )
    },
    customCountryLabel({ name }) {
      return name
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name]
      return $dirty ? !$error : null
    },
    confirm() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }

      // set spinner to submit button
      const confirmButton = this.$refs['confirm_button']
      this.$spinnerLoader.addSpinnerLoader(confirmButton)

      // ApiService.put(`users/${this.myUser.id}`, {
      ApiService.put(`users/me`, {
        country_code: this.notTurkishCitizen ? this.selectedCountry.code : 'TR',
      })
        .then((res) => {
          this.myUser.country_code = this.selectedCountry.code
          this.$store.dispatch(VERIFY_REGISTERED_USER, this.myUser.email)
          this.$generateToast(this, 'success', this.$ml.get('successful'))
          this.$spinnerLoader.removeSpinnerLoader(confirmButton)
        })
        .catch((err) => {
          console.log(err)
          this.$generateToast(
            this,
            'danger',
            this.$ml.get('anErrorHasOccurred')
          )
          this.$spinnerLoader.removeSpinnerLoader(confirmButton)
        })
    },
  },
  components: {},
}
</script>