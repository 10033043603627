<template>
  <Card class="agent">
    <div class="d-flex text-white"
         :class="showAsCard?'flex-column align-items-center':'align-items-start'">
      <div class="mb-3">
        <img src="/image/customer-agent.svg"
             class="img-fluid" />
      </div>
      <div class="d-flex flex-column"
           :class="showAsCard?'text-center':'ml-5'">
        <div class="mb-1">{{ $ml.get('customerRepresentative') }}</div>
        <div class="font-weight-bold mb-5 agent-name">{{this.name}}</div>
        <div class="mb-5">
          {{ $ml.get('canWeHelpYou') }}
        </div>
      </div>
      <div class="d-flex"
           :class="showAsCard?'w-100':'w-25 ml-auto my-auto'">
        <button class="btn btn-primary border agent-cta-button w-100"
                v-b-modal.modal-customer-agent>{{ $ml.get('contactMe') }}</button>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card'

export default {
  name: 'CustomerAgent',
  props: {
    showAsCard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      name: 'Dilara Ödemiş',
    }
  },
  components: {
    Card,
  },
}
</script>
<style lang="scss">
.agent {
  background-color: #00baf2;
  .agent-name {
    font-size: 22px;
    font-weight: 600 !important;
  }
  .agent-cta-button {
    border-color: rgb(255, 255, 255) !important;
  }
}
</style>