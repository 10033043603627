<template>
  <a class="btn btn-light-danger bg-light br-6 font-size-md font-weight-bold px-5">
    <slot></slot>
  </a>
</template>


<script>
export default {
  name: 'ButtonLightDanger',
}
</script>

<style scoped>
.br-6 {
  border-radius: 6px !important;
}

.btn.bg-light:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.bg-light:focus:not(.btn-text),
.btn.bg-light.focus:not(.btn-text) {
  color: #fff !important;
  background-color: #f64e60 !important;
  border-color: transparent !important;
}
</style>