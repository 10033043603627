<template>
  <a class="btn btn-dark font-size-md text-bold px-5">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ButtonDark',
}
</script>