<template>
  <b-modal id="modal-currency-change"
           :title="$ml.get('contactSalesConsultant')"
           centered
           hide-footer
           header-text-variant="light"
           hide-header-close
           size="m">
    <b-container>
      <div class="d-flex flex-column">

        <b-form class="form"
                @submit.stop.prevent="confirm"
                :disabled="loading">
          <b-form-group id="fromGroup"
                        class="mb-3"
                        :label="`${$ml.get('from')}:`"
                        label-for="from">
            <b-form-input id="from"
                          :value="`${this.user.name} ${this.user.surname}` "
                          disabled="true"
                          :placeholder="$ml.get('from')"></b-form-input>
          </b-form-group>
          <b-form-group id="toGroup"
                        class="mb-3"
                        :label="`${$ml.get('to')}:`"
                        label-for="to">
            <b-form-input id="to"
                          :value="`${$ml.get('salesConsultant')}` "
                          disabled="true"
                          :placeholder="$ml.get('to')"></b-form-input>
          </b-form-group>

          <b-form-group id="titleGroup"
                        class="mb-3"
                        :label="`${$ml.get('title')}:`"
                        label-for="title">
            <b-form-input id="title"
                          v-model="form.title"
                          rows="6"
                          :placeholder="$ml.get('title')"></b-form-input>
          </b-form-group>

          <b-form-group id="detailGroup"
                        class="mb-3"
                        :label="`${$ml.get('detail')}:`"
                        label-for="detail">
            <b-form-textarea id="detail"
                             v-model="form.body"
                             rows="6"
                             :placeholder="$ml.get('couldYouGiveUs')"></b-form-textarea>
          </b-form-group>

          <div class="buttons">
            <b-button type="reset"
                      class="px-15 mr-3"
                      @click="$bvModal.hide('modal-currency-change')"
                      variant="danger">{{ $ml.get('cancel') }}</b-button>

            <b-button type="submit"
                      class="px-15"
                      ref="sendCustomer"
                      variant="primary">{{ $ml.get('send') }}</b-button>
          </div>
        </b-form>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import { GET_MY_USER } from '@/core/services/store/auth.module'
import { RUN_LOADER } from '@/core/services/store/htmlclass.module.js'

export default {
  name: 'GeneralSettingsCurrencyModal',
  props: ['currency'],
  data() {
    return {
      form: {
        title: this.$ml.get('currencyChangeRequestTitle'),
        body: this.$ml.get('currencyChangeRequestMessage'),
      },
      user: {},
      loading: false,
    }
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(RUN_LOADER, true)
  },
  mounted() {
    this.getMyUser()
  },
  methods: {
    getMyUser() {
      this.$store.dispatch(GET_MY_USER).then((res) => {
        this.$store.dispatch(RUN_LOADER, false)
      })
    },
    confirm() {
      this.loading = true

      const submitButton = this.$refs['sendCustomer']
      this.$spinnerLoader.addSpinnerLoader(submitButton)

      ApiService.post('support', {
        ...this.form,
        category_ref: 'vyGNGgyNoW',
        priority: 'medium',
        title: this.form.title,
        body: this.form.body,
      })
        .then((res) => {
          this.form = {
            title: '',
            body: '',
          }

          this.loading = false

          this.$spinnerLoader.removeSpinnerLoader(submitButton)

          this.$bvModal.hide('modal-currency-change')
          this.generateToast(
            'success',
            this.$ml.get('sendCustomerMessageSuccess')
          )
        })
        .catch((err) => {
          this.loading = false
          this.$spinnerLoader.removeSpinnerLoader(submitButton)

          if (err == 'Error') {
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          }
        })
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
    setPhoneUrl() {
      if (this.user.cell_phone_code !== '' && this.user.cell_phone !== '') {
        return (
          'tel:' +
          this.user.cell_phone_code +
          this.user.cell_phone.replace(/\s/g, '')
        )
      } else return ''
    },
  },
  computed: {
    ...mapGetters(['myUser', 'isLoading']),
  },
  watch: {
    myUser() {
      this.user = this.myUser
    },
    currency() {
      this.form.title = this.$ml.get('currencyChangeRequestTitle')
      this.form.body =
        this.$ml.get('currencyChangeRequestMessage') + '\n\n' + this.currency
    },
  },
}
</script>

<style lang="scss">
#modal-currency-change {
  header {
    justify-content: center;
  }

  .title {
    text-align: center;
    font-family: Poppins;

    h3 {
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
      color: #212121;
    }

    a {
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;

      .phone {
        color: #b5b5c3;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .buttons {
    text-align: center;
  }
  @media (max-width: 767px) {
    .buttons {
      button {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
      }
    }
  }
}
</style>
