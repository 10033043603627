<template>
  <b-modal id="modal-generalsettings-phone"
           centered
           hide-footer
           hide-header
           header-text-variant="light"
           hide-header-close
           size="m">
    <b-container>
      <div class="d-flex flex-column">
        <p class="text-dark font-size-secondary mb-5 font-weight-bold">
          {{ $ml.get('updateYourGSM') }}
        </p>

        <b-form class="form"
                @submit.stop.prevent="sendCode">
          <p class="mb-5">{{ $ml.get('pleaseEnterGSMNo') }}</p>
          <div class="d-flex">

            <b-form-select id="phoneCode"
                           class="rounded-sm mb-5 mr-1"
                           v-model="phone.phoneCode"
                           :state="validateState('phoneCode')"
                           style="width: 120px">
              <template v-slot:first>
                <b-form-select-option :value="null"
                                      disabled>{{
                    $ml.get('selectPhoneCode')
                  }}</b-form-select-option>
              </template>
              <b-form-select-option value="213">Algeria (+213)</b-form-select-option>
              <b-form-select-option value="376">Andorra (+376)</b-form-select-option>
              <b-form-select-option value="244">Angola (+244)</b-form-select-option>
              <b-form-select-option value="1264">Anguilla (+1264)</b-form-select-option>
              <b-form-select-option value="1268">Antigua &amp; Barbuda (+1268)</b-form-select-option>
              <b-form-select-option value="54">Argentina (+54)</b-form-select-option>
              <b-form-select-option value="374">Armenia (+374)</b-form-select-option>
              <b-form-select-option value="297">Aruba (+297)</b-form-select-option>
              <b-form-select-option value="61">Australia (+61)</b-form-select-option>
              <b-form-select-option value="43">Austria (+43)</b-form-select-option>
              <b-form-select-option value="994">Azerbaijan (+994)</b-form-select-option>
              <b-form-select-option value="1242">Bahamas (+1242)</b-form-select-option>
              <b-form-select-option value="973">Bahrain (+973)</b-form-select-option>
              <b-form-select-option value="880">Bangladesh (+880)</b-form-select-option>
              <b-form-select-option value="1246">Barbados (+1246)</b-form-select-option>
              <b-form-select-option value="375">Belarus (+375)</b-form-select-option>
              <b-form-select-option value="32">Belgium (+32)</b-form-select-option>
              <b-form-select-option value="501">Belize (+501)</b-form-select-option>
              <b-form-select-option value="229">Benin (+229)</b-form-select-option>
              <b-form-select-option value="1441">Bermuda (+1441)</b-form-select-option>
              <b-form-select-option value="975">Bhutan (+975)</b-form-select-option>
              <b-form-select-option value="591">Bolivia (+591)</b-form-select-option>
              <b-form-select-option value="387">Bosnia Herzegovina (+387)</b-form-select-option>
              <b-form-select-option value="267">Botswana (+267)</b-form-select-option>
              <b-form-select-option value="55">Brazil (+55)</b-form-select-option>
              <b-form-select-option value="673">Brunei (+673)</b-form-select-option>
              <b-form-select-option value="359">Bulgaria (+359)</b-form-select-option>
              <b-form-select-option value="226">Burkina Faso (+226)</b-form-select-option>
              <b-form-select-option value="257">Burundi (+257)</b-form-select-option>
              <b-form-select-option value="855">Cambodia (+855)</b-form-select-option>
              <b-form-select-option value="237">Cameroon (+237)</b-form-select-option>
              <b-form-select-option value="1">Canada (+1)</b-form-select-option>
              <b-form-select-option value="238">Cape Verde Islands (+238)</b-form-select-option>
              <b-form-select-option value="1345">Cayman Islands (+1345)</b-form-select-option>
              <b-form-select-option value="236">Central African Republic (+236)</b-form-select-option>
              <b-form-select-option value="56">Chile (+56)</b-form-select-option>
              <b-form-select-option value="86">China (+86)</b-form-select-option>
              <b-form-select-option value="57">Colombia (+57)</b-form-select-option>
              <b-form-select-option value="269">Comoros (+269)</b-form-select-option>
              <b-form-select-option value="242">Congo (+242)</b-form-select-option>
              <b-form-select-option value="682">Cook Islands (+682)</b-form-select-option>
              <b-form-select-option value="506">Costa Rica (+506)</b-form-select-option>
              <b-form-select-option value="385">Croatia (+385)</b-form-select-option>
              <b-form-select-option value="53">Cuba (+53)</b-form-select-option>
              <b-form-select-option value="90392">Cyprus North (+90392)</b-form-select-option>
              <b-form-select-option value="357">Cyprus South (+357)</b-form-select-option>
              <b-form-select-option value="42">Czech Republic (+42)</b-form-select-option>
              <b-form-select-option value="45">Denmark (+45)</b-form-select-option>
              <b-form-select-option value="253">Djibouti (+253)</b-form-select-option>
              <b-form-select-option value="1809">Dominica (+1809)</b-form-select-option>
              <b-form-select-option value="1809">Dominican Republic (+1809)</b-form-select-option>
              <b-form-select-option value="593">Ecuador (+593)</b-form-select-option>
              <b-form-select-option value="20">Egypt (+20)</b-form-select-option>
              <b-form-select-option value="503">El Salvador (+503)</b-form-select-option>
              <b-form-select-option value="240">Equatorial Guinea (+240)</b-form-select-option>
              <b-form-select-option value="291">Eritrea (+291)</b-form-select-option>
              <b-form-select-option value="372">Estonia (+372)</b-form-select-option>
              <b-form-select-option value="251">Ethiopia (+251)</b-form-select-option>
              <b-form-select-option value="500">Falkland Islands (+500)</b-form-select-option>
              <b-form-select-option value="298">Faroe Islands (+298)</b-form-select-option>
              <b-form-select-option value="679">Fiji (+679)</b-form-select-option>
              <b-form-select-option value="358">Finland (+358)</b-form-select-option>
              <b-form-select-option value="33">France (+33)</b-form-select-option>
              <b-form-select-option value="594">French Guiana (+594)</b-form-select-option>
              <b-form-select-option value="689">French Polynesia (+689)</b-form-select-option>
              <b-form-select-option value="241">Gabon (+241)</b-form-select-option>
              <b-form-select-option value="220">Gambia (+220)</b-form-select-option>
              <b-form-select-option value="7880">Georgia (+7880)</b-form-select-option>
              <b-form-select-option value="49">Germany (+49)</b-form-select-option>
              <b-form-select-option value="233">Ghana (+233)</b-form-select-option>
              <b-form-select-option value="350">Gibraltar (+350)</b-form-select-option>
              <b-form-select-option value="30">Greece (+30)</b-form-select-option>
              <b-form-select-option value="299">Greenland (+299)</b-form-select-option>
              <b-form-select-option value="1473">Grenada (+1473)</b-form-select-option>
              <b-form-select-option value="590">Guadeloupe (+590)</b-form-select-option>
              <b-form-select-option value="671">Guam (+671)</b-form-select-option>
              <b-form-select-option value="502">Guatemala (+502)</b-form-select-option>
              <b-form-select-option value="224">Guinea (+224)</b-form-select-option>
              <b-form-select-option value="245">Guinea - Bissau (+245)</b-form-select-option>
              <b-form-select-option value="592">Guyana (+592)</b-form-select-option>
              <b-form-select-option value="509">Haiti (+509)</b-form-select-option>
              <b-form-select-option value="504">Honduras (+504)</b-form-select-option>
              <b-form-select-option value="852">Hong Kong (+852)</b-form-select-option>
              <b-form-select-option value="36">Hungary (+36)</b-form-select-option>
              <b-form-select-option value="354">Iceland (+354)</b-form-select-option>
              <b-form-select-option value="91">India (+91)</b-form-select-option>
              <b-form-select-option value="62">Indonesia (+62)</b-form-select-option>
              <b-form-select-option value="98">Iran (+98)</b-form-select-option>
              <b-form-select-option value="964">Iraq (+964)</b-form-select-option>
              <b-form-select-option value="353">Ireland (+353)</b-form-select-option>
              <b-form-select-option value="972">Israel (+972)</b-form-select-option>
              <b-form-select-option value="39">Italy (+39)</b-form-select-option>
              <b-form-select-option value="1876">Jamaica (+1876)</b-form-select-option>
              <b-form-select-option value="81">Japan (+81)</b-form-select-option>
              <b-form-select-option value="962">Jordan (+962)</b-form-select-option>
              <b-form-select-option value="7">Kazakhstan (+7)</b-form-select-option>
              <b-form-select-option value="254">Kenya (+254)</b-form-select-option>
              <b-form-select-option value="686">Kiribati (+686)</b-form-select-option>
              <b-form-select-option value="850">Korea North (+850)</b-form-select-option>
              <b-form-select-option value="82">Korea South (+82)</b-form-select-option>
              <b-form-select-option value="965">Kuwait (+965)</b-form-select-option>
              <b-form-select-option value="996">Kyrgyzstan (+996)</b-form-select-option>
              <b-form-select-option value="856">Laos (+856)</b-form-select-option>
              <b-form-select-option value="371">Latvia (+371)</b-form-select-option>
              <b-form-select-option value="961">Lebanon (+961)</b-form-select-option>
              <b-form-select-option value="266">Lesotho (+266)</b-form-select-option>
              <b-form-select-option value="231">Liberia (+231)</b-form-select-option>
              <b-form-select-option value="218">Libya (+218)</b-form-select-option>
              <b-form-select-option value="417">Liechtenstein (+417)</b-form-select-option>
              <b-form-select-option value="370">Lithuania (+370)</b-form-select-option>
              <b-form-select-option value="352">Luxembourg (+352)</b-form-select-option>
              <b-form-select-option value="853">Macao (+853)</b-form-select-option>
              <b-form-select-option value="389">Macedonia (+389)</b-form-select-option>
              <b-form-select-option value="261">Madagascar (+261)</b-form-select-option>
              <b-form-select-option value="265">Malawi (+265)</b-form-select-option>
              <b-form-select-option value="60">Malaysia (+60)</b-form-select-option>
              <b-form-select-option value="960">Maldives (+960)</b-form-select-option>
              <b-form-select-option value="223">Mali (+223)</b-form-select-option>
              <b-form-select-option value="356">Malta (+356)</b-form-select-option>
              <b-form-select-option value="692">Marshall Islands (+692)</b-form-select-option>
              <b-form-select-option value="596">Martinique (+596)</b-form-select-option>
              <b-form-select-option value="222">Mauritania (+222)</b-form-select-option>
              <b-form-select-option value="269">Mayotte (+269)</b-form-select-option>
              <b-form-select-option value="52">Mexico (+52)</b-form-select-option>
              <b-form-select-option value="691">Micronesia (+691)</b-form-select-option>
              <b-form-select-option value="373">Moldova (+373)</b-form-select-option>
              <b-form-select-option value="377">Monaco (+377)</b-form-select-option>
              <b-form-select-option value="976">Mongolia (+976)</b-form-select-option>
              <b-form-select-option value="1664">Montserrat (+1664)</b-form-select-option>
              <b-form-select-option value="212">Morocco (+212)</b-form-select-option>
              <b-form-select-option value="258">Mozambique (+258)</b-form-select-option>
              <b-form-select-option value="95">Myanmar (+95)</b-form-select-option>
              <b-form-select-option value="264">Namibia (+264)</b-form-select-option>
              <b-form-select-option value="674">Nauru (+674)</b-form-select-option>
              <b-form-select-option value="977">Nepal (+977)</b-form-select-option>
              <b-form-select-option value="31">Netherlands (+31)</b-form-select-option>
              <b-form-select-option value="687">New Caledonia (+687)</b-form-select-option>
              <b-form-select-option value="64">New Zealand (+64)</b-form-select-option>
              <b-form-select-option value="505">Nicaragua (+505)</b-form-select-option>
              <b-form-select-option value="227">Niger (+227)</b-form-select-option>
              <b-form-select-option value="234">Nigeria (+234)</b-form-select-option>
              <b-form-select-option value="683">Niue (+683)</b-form-select-option>
              <b-form-select-option value="672">Norfolk Islands (+672)</b-form-select-option>
              <b-form-select-option value="670">Northern Marianas (+670)</b-form-select-option>
              <b-form-select-option value="47">Norway (+47)</b-form-select-option>
              <b-form-select-option value="968">Oman (+968)</b-form-select-option>
              <b-form-select-option value="680">Palau (+680)</b-form-select-option>
              <b-form-select-option value="507">Panama (+507)</b-form-select-option>
              <b-form-select-option value="675">Papua New Guinea (+675)</b-form-select-option>
              <b-form-select-option value="595">Paraguay (+595)</b-form-select-option>
              <b-form-select-option value="51">Peru (+51)</b-form-select-option>
              <b-form-select-option value="63">Philippines (+63)</b-form-select-option>
              <b-form-select-option value="48">Poland (+48)</b-form-select-option>
              <b-form-select-option value="351">Portugal (+351)</b-form-select-option>
              <b-form-select-option value="1787">Puerto Rico (+1787)</b-form-select-option>
              <b-form-select-option value="974">Qatar (+974)</b-form-select-option>
              <b-form-select-option value="262">Reunion (+262)</b-form-select-option>
              <b-form-select-option value="40">Romania (+40)</b-form-select-option>
              <b-form-select-option value="7">Russia (+7)</b-form-select-option>
              <b-form-select-option value="250">Rwanda (+250)</b-form-select-option>
              <b-form-select-option value="378">San Marino (+378)</b-form-select-option>
              <b-form-select-option value="239">Sao Tome &amp; Principe (+239)</b-form-select-option>
              <b-form-select-option value="966">Saudi Arabia (+966)</b-form-select-option>
              <b-form-select-option value="221">Senegal (+221)</b-form-select-option>
              <b-form-select-option value="381">Serbia (+381)</b-form-select-option>
              <b-form-select-option value="248">Seychelles (+248)</b-form-select-option>
              <b-form-select-option value="232">Sierra Leone (+232)</b-form-select-option>
              <b-form-select-option value="65">Singapore (+65)</b-form-select-option>
              <b-form-select-option value="421">Slovak Republic (+421)</b-form-select-option>
              <b-form-select-option value="386">Slovenia (+386)</b-form-select-option>
              <b-form-select-option value="677">Solomon Islands (+677)</b-form-select-option>
              <b-form-select-option value="252">Somalia (+252)</b-form-select-option>
              <b-form-select-option value="27">South Africa (+27)</b-form-select-option>
              <b-form-select-option value="34">Spain (+34)</b-form-select-option>
              <b-form-select-option value="94">Sri Lanka (+94)</b-form-select-option>
              <b-form-select-option value="290">St. Helena (+290)</b-form-select-option>
              <b-form-select-option value="1869">St. Kitts (+1869)</b-form-select-option>
              <b-form-select-option value="1758">St. Lucia (+1758)</b-form-select-option>
              <b-form-select-option value="249">Sudan (+249)</b-form-select-option>
              <b-form-select-option value="597">Suriname (+597)</b-form-select-option>
              <b-form-select-option value="268">Swaziland (+268)</b-form-select-option>
              <b-form-select-option value="46">Sweden (+46)</b-form-select-option>
              <b-form-select-option value="41">Switzerland (+41)</b-form-select-option>
              <b-form-select-option value="963">Syria (+963)</b-form-select-option>
              <b-form-select-option value="886">Taiwan (+886)</b-form-select-option>
              <b-form-select-option value="7">Tajikstan (+7)</b-form-select-option>
              <b-form-select-option value="66">Thailand (+66)</b-form-select-option>
              <b-form-select-option value="228">Togo (+228)</b-form-select-option>
              <b-form-select-option value="676">Tonga (+676)</b-form-select-option>
              <b-form-select-option value="1868">Trinidad &amp; Tobago (+1868)</b-form-select-option>
              <b-form-select-option value="216">Tunisia (+216)</b-form-select-option>
              <b-form-select-option value="90">Turkey (+90)</b-form-select-option>
              <b-form-select-option value="7">Turkmenistan (+7)</b-form-select-option>
              <b-form-select-option value="993">Turkmenistan (+993)</b-form-select-option>
              <b-form-select-option value="1649">Turks &amp; Caicos Islands (+1649)</b-form-select-option>
              <b-form-select-option value="688">Tuvalu (+688)</b-form-select-option>
              <b-form-select-option value="256">Uganda (+256)</b-form-select-option>
              <b-form-select-option value="44">UK (+44)</b-form-select-option>
              <b-form-select-option value="380">Ukraine (+380)</b-form-select-option>
              <b-form-select-option value="971">United Arab Emirates (+971)</b-form-select-option>
              <b-form-select-option value="598">Uruguay (+598)</b-form-select-option>
              <b-form-select-option value="1">USA (+1)</b-form-select-option>
              <b-form-select-option value="7">Uzbekistan (+7)</b-form-select-option>
              <b-form-select-option value="678">Vanuatu (+678)</b-form-select-option>
              <b-form-select-option value="379">Vatican City (+379)</b-form-select-option>
              <b-form-select-option value="58">Venezuela (+58)</b-form-select-option>
              <b-form-select-option value="84">Vietnam (+84)</b-form-select-option>
              <b-form-select-option value="84">Virgin Islands - British (+1284)</b-form-select-option>
              <b-form-select-option value="84">Virgin Islands - US (+1340)</b-form-select-option>
              <b-form-select-option value="681">Wallis &amp; Futuna (+681)</b-form-select-option>
              <b-form-select-option value="969">Yemen (North)(+969)</b-form-select-option>
              <b-form-select-option value="967">Yemen (South)(+967)</b-form-select-option>
              <b-form-select-option value="260">Zambia (+260)</b-form-select-option>
              <b-form-select-option value="263">Zimbabwe (+263)</b-form-select-option>
            </b-form-select>

            <!-- <b-form-input v-model="$v.phone.phoneCode.$model"
                          placeholder="90"
                          v-mask="['##']"
                          :state="validateState('phoneCode')"
                          class="mb-5 mr-1"
                          style="width: 70px"></b-form-input> -->
            <b-form-input v-model="$v.phone.phone.$model"
                          v-mask="['### ### ## ##']"
                          :state="validateState('phone')"
                          placeholder="555 555 55 55"
                          class="mb-5"></b-form-input>
          </div>
          <button class="btn btn-primary w-100 d-block px-9 py-3 mb-5 rounded-sm"
                  ref="send_phone_code">
            {{ $ml.get('saveAndSendConfirmationCode') }}
          </button>
        </b-form>

        <!-- <p class="mb-5">
          {{ $ml.get('confirmEmailConfirmationCode') }}
          <u>{{ $ml.get('tryAgain') }}</u>
        </p> -->

        <b-form class="form"
                @submit.stop.prevent="confirm">
          <b-form-input v-model="$v.code.code.$model"
                        :placeholder="$ml.get('confirmationCode')"
                        :state="validateState('code')"
                        class="mb-5"></b-form-input>
          <button class="btn btn-primary w-100 d-block px-9 py-3 rounded-sm"
                  ref="confirm_code">
            {{ $ml.get('confirm') }}
          </button>
        </b-form>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import { VERIFY_REGISTERED_USER } from '@/core/services/store/auth.module'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'GeneralSettingsPhoneModal',
  mixins: [validationMixin],
  data() {
    return {
      code: {
        code: '',
      },
      phone: {
        phone: '',
        phoneCode: this.$ml.current == 'tr' ? '90' : '',
      },
    }
  },
  validations: {
    phone: {
      phoneCode: {
        required,
        minLength: minLength(1),
      },
      phone: {
        required,
        minLength: minLength(12),
      },
    },
    code: {
      code: {
        required,
        minLength: minLength(3),
      },
    },
  },
  mounted() {
    // Changed lang events
    this.$ml.bus.$on('vueml-language-changed', () => {
      this.phone.phoneCode = this.$ml.current == 'tr' ? 90 : ''
    })
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } =
        name == 'code' ? this.$v.code[name] : this.$v.phone[name]
      return $dirty ? !$error : null
    },
    sendCode() {
      this.$v.phone.$touch()
      if (this.$v.phone.$anyError) {
        return
      }
      // set spinner to submit button
      const sendPhoneCode = this.$refs['send_phone_code']
      this.$spinnerLoader.addSpinnerLoader(sendPhoneCode)

      ApiService.put('mobile/send-verification-code', {
        mobile_phone_code: this.phone.phoneCode,
        mobile_phone: this.phone.phone.split(' ').join(''),
      })
        .then((res) => {
          this.generateToast(
            'success',
            this.$ml.get('confirmationCodeHasBeenSentPhone')
          )
          this.$spinnerLoader.removeSpinnerLoader(sendPhoneCode)
        })
        .catch((err) => {
          console.log(err)
          this.$spinnerLoader.removeSpinnerLoader(sendPhoneCode)

          if (err == 'Error') {
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          }
        })
    },
    confirm() {
      this.$v.code.$touch()
      if (this.$v.code.$anyError) {
        return
      }

      // set spinner to submit button
      const confirmCode = this.$refs['confirm_code']
      this.$spinnerLoader.addSpinnerLoader(confirmCode)

      ApiService.put('mobile/verify', {
        mobile_phone_code: this.phone.phoneCode,
        mobile_phone: this.phone.phone.split(' ').join(''),
        token: this.code.code,
      })
        .then((res) => {
          this.$store
            .dispatch(VERIFY_REGISTERED_USER, this.currentUser.user.email)
            .then((res) => {
              if (res.is_cellphone_validated) {
                this.generateToast(
                  'success',
                  this.$ml.get('yourPhoneConfirmed')
                )
                setTimeout(() => this.$router.go(), 1000)
              } else {
                this.generateToast(
                  'danger',
                  this.$ml.get('yourPhoneConfirmError')
                )
                this.$bvModal.hide('modal-generalsettings-phone')
              }
              this.$spinnerLoader.removeSpinnerLoader(confirmCode)
            })
        })
        .catch((err) => {
          console.log(err)
          this.$spinnerLoader.removeSpinnerLoader(confirmCode)

          if (err == 'Error') {
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          }
        })
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
}
</script>
