<template>
  <Card>
    <CardLoader v-if="isLoading"></CardLoader>
    <template #header>
      <h3 class="text-dark font-size-secondary">
        {{ currentUser.user.fullname }}
      </h3>
    </template>

    <template v-if="!isLoading">
      <div class="d-flex align-items-center flex-wrap mb-10">
        <UserInfo :picture="this.myUser.picture"
                  :email="currentUser.user.email"
                  :date="$customFunctions.setMomentWithFormat(myUser.created_at,'L LT')">
        </UserInfo>
        <div class="btn-group mt-3 mt-sm-0 mt-lg-3 mt-xxl-0"
             v-if="false">
          <div class="buttons mr-3">
            <ButtonDark href="#">
              Create Team
            </ButtonDark>
          </div>
          <div class="buttons">
            <ButtonDanger href="#">
              Delete Acccount
            </ButtonDanger>
          </div>
        </div>
      </div>

      <div class="social-media"
           v-if="false">
        <p class="text-dark font-weight-bolder font-size-h5 m-0">
          Social Profiles
        </p>
        <p class="text-dark font-weight-light">
          You have not shared your social profile accounts with us yet!
        </p>

        <div class="social-buttons mt-5">
          <SocialButton class="btn-light-dotted flex-fill"
                        href="#">
            <i class="icon-xl fab fa-gitlab"></i>
            GitLab
          </SocialButton>
          <SocialButton class="btn-light-dotted flex-fill"
                        href="#">
            <i class="icon-xl fab fa-github"></i>
            GitHub
          </SocialButton>
          <SocialButton class="btn-light-dotted flex-fill"
                        href="#">
            <i class="icon-xl fab fa-facebook-f"></i>
            Facebook
          </SocialButton>
          <SocialButton class="btn-light-dotted flex-fill"
                        href="#">
            <i class="icon-xl fab fa-twitter"></i>
            Twitter
          </SocialButton>
          <SocialButton class="btn-light-dotted flex-fill"
                        href="#">
            <i class="icon-xl fab fa-instagram"></i>
            Instagram
          </SocialButton>
          <SocialButton class="btn-light-dotted flex-fill"
                        href="#">
            <i class="icon-xl fab fa-linkedin"></i>
            LinkedIn
          </SocialButton>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from '@/components/Card'
import ButtonDark from '@/components/buttons/ButtonDark'
import ButtonDanger from '@/components/buttons/ButtonDanger'
import SocialButton from '@/components/buttons/SocialButton'
import UserInfo from '@/components/UserInfo'
import CardLoader from '@/view/content/CardLoader'

export default {
  name: 'UserCard',
  computed: {
    ...mapGetters(['currentUser', 'myUser', 'isLoading']),
    userName() {
      let fullname = this.currentUser.user.fullname.split(' ')

      if (fullname.length >= 2) {
        return fullname[0].slice(0, 1) + fullname[1].slice(0, 1)
      } else if (fullname.length >= 1) {
        return fullname[0].slice(0, 1)
      } else {
        return 'NaN'
      }
    },
  },
  components: {
    Card,
    ButtonDark,
    ButtonDanger,
    SocialButton,
    UserInfo,
    CardLoader,
  },
}
</script>

<style lang="scss" scoped>
.social-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
</style>
