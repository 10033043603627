<template>
  <b-modal
    id="modal-usage-agreement"
    hide-header-close
    hide-footer
    centered
    size="md"
  >
    <template v-slot:modal-header>
      <div class="d-flex text-white">
        <div class="flex-grow-1 d-flex flex-column text-center">
          <div class="font-weight-bold title text-uppercase">
            {{ $ml.get('usageAgreement') }}
          </div>
        </div>
      </div>
    </template>
    <b-container
      class="agreement-content"
      v-html="replaceHtml(this.catalogAgreement)"
    >
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: 'UsageAgreementModal',
  props: {
    catalogAgreement: String,
  },
  methods: {
    replaceHtml(value) {
      if (typeof value !== 'undefined' && value !== null && value !== '') {
        value = value.replace(/\r\n/g, '<br />')
        value = value.replace(/\n/g, '<br />')
      }
      return value
    },
  },
}
</script>

<style lang="scss">
#modal-usage-agreement {
  .modal-content {
    background-color: #ffffff;
  }
  .modal-header {
    background-color: #f58220;
    display: inline;
    .title {
      font-size: 18px;
      font-weight: 700 !important;
    }
    .subtitle {
      font-size: 12px;
      font-weight: 500 !important;
    }
  }
  .modal-body {
    // background-color: #ffffff;
    .title {
      font-weight: 700;
      font-size: 17px;
    }
    .agreement-content {
      max-height: 500px;
      overflow: auto;
      ol {
        counter-reset: item;
      }
      ol li:not(.sub) {
        display: block;
        position: relative;
      }
      ol li:not(.sub):before {
        content: counters(item, '.') '.';
        counter-increment: item;
        position: absolute;
        margin-right: 100%;
        right: 3px; /* space between number and text */
      }
    }
  }
}
</style>