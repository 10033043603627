<template>
  <div>
    <b-modal id="modal-dashboardconfirm-skip"
             centered
             hide-footer
             hide-header
             header-text-variant="light"
             hide-header-close
             size="m">
      <b-container>
        <b-form class="form"
                @submit.stop.prevent="confirm">
          <div class="d-flex flex-column">
            <p class="text-dark font-size-secondary mb-5 font-weight-bold">
              {{ $ml.get('letUsCallYou') }}
            </p>
            <p class="mb-5">{{ $ml.get('letUsCallYouInfoText') }}</p>

            <b-form-datepicker v-model="$v.day.$model"
                               :min="min"
                               :max="max"
                               :start-weekday="$ml.current == 'tr' ? 1 : 0"
                               :state="validateState('day')"
                               class="form-control mb-3"
                               :placeholder="$ml.get('chooseDay')"
                               :locale="$ml.current"></b-form-datepicker>

            <b-form-timepicker v-model="$v.time.$model"
                               :minutes-step="10"
                               :state="validateState('time')"
                               :locale="$ml.current"
                               :placeholder="$ml.get('chooseTime')"
                               class="form-control mb-5"></b-form-timepicker>

            <button class="btn btn-primary w-100 d-block px-9 py-3 rounded-sm"
                    ref="skip_button">{{
            $ml.get('okey')
          }}</button>
          </div>
        </b-form>
      </b-container>
    </b-modal>

    <b-modal id="modal-dashboardconfirm-skip-complete"
             centered
             hide-footer
             hide-header
             header-text-variant="light"
             hide-header-close
             body-bg-variant="success"
             body-text-variant="white"
             size="m">
      <b-container>
        <div class="d-flex flex-column">
          <p class="text-white font-size-secondary mb-5 font-weight-bold">
            {{ $ml.get('successful') }}
          </p>
          <p class="mb-5">{{ $ml.get('letUsCallYouSuccessText') }}</p>
          <b-button variant="white"
                    class="rounded-sm text-sucess"
                    @click="confirmSuccess()">{{ $ml.get('okey') }}</b-button>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import { SKIP_WELCOME } from '@/core/services/store/welcomePage.module'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'DashboardConfirmSkipModal',
  mixins: [validationMixin],
  data() {
    const now = new Date()
    const minDate = new Date(now)
    // 15th in two months
    const maxDate = new Date(now)
    maxDate.setDate(maxDate.getDay() + 20)

    return {
      day: '',
      time: '12:00:00',
      min: minDate,
      max: maxDate,
    }
  },
  validations: {
    day: {
      required,
    },
    time: {
      required,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name]
      return $dirty ? !$error : null
    },
    confirm() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }

      // set spinner to skip button
      const skipButton = this.$refs['skip_button']
      this.$spinnerLoader.addSpinnerLoader(skipButton)

      let fullname = this.currentUser.user.fullname

      let name = fullname.split(' ').slice(0, -1).join(' ')
      let surname = fullname.split(' ').slice(-1).join(' ')

      ApiService.post('crm/save/lead', {
        name: name,
        surname: surname,
        email: this.currentUser.user.email,
        notes: `Day: ${this.day} Time: ${this.time}`,
      })
        .then((res) => {
          this.$bvModal.hide('modal-dashboardconfirm-skip')
          this.$bvModal.show('modal-dashboardconfirm-skip-complete')

          this.$spinnerLoader.removeSpinnerLoader(skipButton)
        })
        .catch((error) => {
          if (error.response && error.response.data.error.errors.email) {
            this.$generateToast(
              this,
              'danger',
              this.$ml.get('yourRequestHasAlreadyBeenReceived')
            )
          } else {
            this.$generateToast(
              this,
              'danger',
              this.$ml.get('anErrorHasOccurred')
            )
          }

          this.$spinnerLoader.removeSpinnerLoader(skipButton)
        })
    },
    confirmSuccess() {
      this.$store.dispatch(SKIP_WELCOME, true)
      this.$bvModal.hide('modal-dashboardconfirm-skip-complete')
    },
  },
}
</script>

<style lang="scss">
.modal-content {
  overflow: visible !important;
}
.form-control.is-valid,
.form-control.is-invalid {
  padding-right: unset !important;
}
</style>