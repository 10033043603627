<template>
  <b-modal id="modal-teknosa-stash-banner"
           hide-header-close
           hide-footer
           centered
           size="xl">
    <template v-slot:modal-header>
      <img src="/image/teknosa-banner/teknosacell-modal.svg"
           alt="teknosacell"
           class="img-fluid teknosa-modal-image" />
      <div class="d-flex text-white">
        <div class="flex-grow-1 d-flex flex-column text-center">
          <div class="font-weight-bold title">{{ $ml.get('packets') }}</div>
          <div class="subtitle">
            {{ $ml.get('monthlyPackagesWithandWithoutCommitment') }}
          </div>
        </div>
      </div>
    </template>
    <b-container class="d-flex flex-column align-items-center">
      <TeknosaGiftBanner class="max-555"></TeknosaGiftBanner>

      <template>
        <v-data-table :headers="headers"
                      :items="packets"
                      :options.sync="options"
                      :items-per-page="100"
                      :server-items-length="100"
                      :loading="loading"
                      :hide-default-footer="true"
                      id="packet-list">

          <template v-slot:header.args.quota>
            {{$ml.get('infrastructureResource')}}
          </template>
          <template v-slot:header.piece>
            {{$ml.get('piece')}}
          </template>
          <template v-slot:header.unregistered>
            {{appName()}} & Teknosacell {{$ml.get('unregistered')}}
          </template>
          <template v-slot:header.registered>
            {{appName()}} & Teknosacell {{$ml.get('registered')}}
          </template>
          <template v-slot:header.subscription>
            {{$ml.get('subscription')}}
          </template>
          <template v-slot:item.piece="{ item }">
            1
          </template>

          <template v-slot:item.unregistered="{ item }">
            <span class="font-weight-bold">{{ item.price | formatPrice }}
              {{ item.currency_code }}</span>
            / {{ item.subscription_type }}
          </template>

          <template v-slot:item.registered="{ item }">
            <span class="font-weight-bold">{{ item.campaign_price | formatPrice }}
              {{ item.currency_code }}</span>
            / {{ item.subscription_type }}
          </template>

          <template v-slot:item.subscription="{ item }">
            <button class="d-flex justify-content-center btn btn-primary btn-subscribe mt-2 mb-2"
                    @click="Subscribe(item)">
              {{
                      item.campaign_price > 0
                        ? $ml.get('subscribe')
                        : $ml.get('subscribeForFree')
                    }}
            </button>
          </template>

        </v-data-table>
      </template>

      <!-- <p class="text-muted footer-text mt-5">
        *Some descp. comes here. Lorem ipsum dolor sit amet
      </p> -->
    </b-container>
  </b-modal>
</template>

<script>
import ApiService from '@/core/services/api.service'
import TeknosaGiftBanner from '@/components/general/TeknosaGiftBanner'

export default {
  name: 'TeknosaStashModal',
  data() {
    return {
      stashId: '',
      packets: [],
      options: {},
      loading: false,
      headers: [
        {
          text: this.$ml.get('infrastructureResource'),
          value: 'args.quota',
          sortable: false,
        },
        { text: this.$ml.get('piece'), value: 'piece', sortable: false },
        {
          text: 'PlusClouds & Teknosacell ' + this.$ml.get('unregistered'),
          value: 'unregistered',
          sortable: false,
        },
        {
          text: 'PlusClouds & Teknosacell ' + this.$ml.get('registered'),
          value: 'registered',
          sortable: false,
        },
        {
          text: this.$ml.get('subscription'),
          value: 'subscription',
          sortable: false,
        },
      ],
    }
  },
  created() {
    this.getMarketplaceStashDetail().then((res) => {
      if (res != null && res.data.length > 0) {
        this.stashId = res.data[0].id
        this.getCatalogs()
      }
    })
  },
  methods: {
    getMarketplaceStashDetail() {
      this.loading = true
      return new Promise((resolve, reject) => {
        ApiService.get(`marketplace?slug=plusclouds-stash`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getCatalogs() {
      return new Promise((resolve, reject) => {
        ApiService.get(`/marketplace/${this.stashId}/catalogs`)
          .then((data) => {
            this.packets = data.data.data
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            reject(err)
          })
      })
    },
    Subscribe(catalog) {
      this.$emit('selectedCatalog', catalog)
      this.$bvModal.hide('modal-teknosa-stash-banner')

      if (catalog.campaign_price > 0) {
        this.$bvModal.show('modal-teknosa-stash-banner-payment')
      } else {
        this.$bvModal.show('modal-teknosa-stash-banner-free')
      }
    },
    appName() {
      return process.env.VUE_APP_NAME
    },
  },
  filters: {
    formatPrice(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
  },
  components: {
    TeknosaGiftBanner,
  },
}
</script>

<style lang="scss">
#modal-teknosa-stash-banner {
  .modal-content {
    background-color: #ffffff;
  }
  .modal-header {
    background-color: #f58220;
    display: inline;
    .title {
      font-size: 29px;
      font-weight: 700 !important;
    }
    .subtitle {
      font-size: 12px;
      font-weight: 500 !important;
    }
    .teknosa-modal-image {
      position: absolute;
      right: 20px;
      height: 65px;
    }
  }
  .modal-body {
    background-color: #ffffff;
  }
  .footer-text {
    font-size: 12px;
  }

  #packet-list {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;

    table {
      min-height: 120px;

      th {
        font-weight: 600;
        font-size: 14px;
        color: #0a1f44;
        border-right: thin solid rgba(0, 0, 0, 0.12);
      }

      td {
        border-right: thin solid rgba(0, 0, 0, 0.12);
      }

      .btn-subscribe {
        font-weight: 500;
        font-size: 12px;
        width: 150px;
        line-height: 18px;
      }
    }
  }
  .no-data-table {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.38);
    height: 48px;
    padding: 0px 16px;
    -webkit-transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 767px) {
    .title {
      font-size: 18px !important;
      text-align: left !important;
    }
    .subtitle {
      font-weight: 300 !important;
      font-size: 12px !important;
      text-align: left !important;
    }
    .teknosa-modal-image {
      height: 55px !important;
    }
    .v-data-table__mobile-row:last-child {
      .v-data-table__mobile-row__header {
        display: none;
      }
    }
    .v-data-table__mobile-row__cell {
      width: calc(100vw - 94px) !important;
    }
    #packet-list {
      border: none;

      table {
        th,
        td {
          border: none;
        }
      }
    }
  }
}
</style>
