<template>
  <Card>
    <CardLoader v-if="isLoading"></CardLoader>
    <template #header>
      <div class="title py-5">
        <h3 class="text-dark font-size-secondary m-0">{{ $ml.get('accountType') }}</h3>
        <span v-if="!isHaveAccountType"
              class="text-muted font-size-md">{{ $ml.get('updateAccountTypeText') }}</span>
        <span v-else
              class="text-muted font-size-md">{{ $ml.get('yourAccountType') }}:</span>
      </div>
    </template>

    <template v-if="!isLoading">
      <div class="form"
           v-if="!isHaveAccountType">
        <div class="select mb-5">
          <label>{{ $ml.get('accountType') }}</label>
          <multiselect v-model="selectedAccountType"
                       label="description"
                       track-by="description"
                       :options="accountTypes"
                       :allowEmpty="false"
                       :show-labels="false"
                       :searchable="true"
                       :placeholder="$ml.get('pleaseSelectAccountType')"
                       required></multiselect>
        </div>
        <div class="button">
          <ButtonPrimary class="w-auto"
                         @click.native="changeAccountType">
            {{ $ml.get('saveChanges') }}
          </ButtonPrimary>
        </div>
      </div>
      <div class="account"
           v-else>
        <hr />
        <p class="text-dark">
          {{ meAccountType }}
        </p>
        <hr />
      </div>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import Card from '@/components/Card'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import CardLoader from '@/view/content/CardLoader'

export default {
  name: 'AccountType',
  props: {
    accountTypes: Array,
  },
  data() {
    return {
      selectedAccountType: '',
    }
  },
  methods: {
    changeAccountType() {
      ApiService.put(`accounts/change-type`, {
        account_type_id: this.selectedAccountType.id,
      }).then((res) => {
        console.log(res)
      })
    },
  },
  computed: {
    ...mapGetters(['myUser', 'isLoading']),
    isHaveAccountType() {
      return this.myUser.currentAccount && this.myUser.currentAccount.data
        ? !!this.myUser.currentAccount.data.account_type
        : false
    },
    meAccountType() {
      return this.myUser.currentAccount.data.account_type.description
    },
  },
  watch: {
    myUser() {
      this.selectedAccountType = this.myUser.currentAccount.data.account_type
    },
  },
  components: {
    Card,
    ButtonPrimary,
    CardLoader,
  },
}
</script>
