<template>
  <div class="user-info d-flex align-items-center flex-fill">
    <!--begin::Symbol-->
    <div class="symbol symbol-50 mr-5"> 
      <img :src="picture">
    </div>
    <!--end::Symbol-->
    <!--begin::Text-->
    <div class="d-flex flex-column flex-grow-1 mr-2">
      <a href="#"
         class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
        {{ email }}
      </a>
      <span class="text-muted font-weight-bold">
        {{ $ml.get('memberSince') }}, {{ date }}
      </span>
    </div>
    <!--end::Text-->
  </div>
</template>

<script>
export default {
  name: 'UserInfo',
  props: { 
    picture: String,
    email: String,
    date: String,
  },
}
</script>
