<template>
  <Card class="general-setttings-collapse">
    <CardLoader v-if="isLoading">
      <rect x="0"
            y="4"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="24"
            width="150"
            height="12"
            rx="3" />
      <rect x="0"
            y="56"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="76"
            width="370"
            height="12"
            rx="3" />
      <rect x="0"
            y="96"
            width="178"
            height="12"
            rx="3" />
    </CardLoader>
    <template #header>
      <div class="title py-5">
        <h3 class="text-dark font-size-secondary m-0">
          {{ $ml.get('generalSettings') }}
        </h3>
      </div>
    </template>

    <template v-if="!isLoading">
      <div class="form"
           :disabled="ajaxLoader">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="d-flex">
              <div class="d-flex align-items-center text-dark font-weight-bold font-size-lg">
                {{ $ml.get('basic') }}
              </div>
              <div class="d-flex align-items-center ml-auto setting-arrow d-md-none">
                <i class="fas"
                   :class="this.mobileVisibleBasic ? ' fa-angle-down':' fa-angle-right'"
                   @click="changeOnmobileVisibleBasic()"></i>
              </div>
            </div>
            <hr />
          </div>
          <div class="basic-settings row col-12 m-0 p-0"
               :class="this.mobileVisibleBasic ? '':'d-none d-md-flex'">
            <div class="col-md-6">
              <b-form-group id="nameGroup"
                            :label="`${$ml.get('name')}:`"
                            label-for="name">
                <b-form-input id="name"
                              v-model="user.name"
                              required
                              :placeholder="$ml.get('enterName')"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group id="passwordGroup"
                            :label="`${$ml.get('password')}:`"
                            label-for="currentPassword">
                <b-form-input id="currentPassword"
                              v-model="user.currentPassword"
                              type="password"
                              autocomplete="off"
                              required
                              :class="this.error.password? 'border border-danger':''"
                              :placeholder="$ml.get('enterYourCurrentPassword')"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group id="surnameGroup"
                            :label="`${$ml.get('surname')}:`"
                            label-for="surname">
                <b-form-input id="surname"
                              v-model="user.surname"
                              required
                              :placeholder="$ml.get('enterSurname')"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group id="newPasswordGroup"
                            :label="`${$ml.get('newPassword')}:`"
                            label-for="newPassword">
                <b-form-input id="newPassword"
                              v-model="user.newPassword"
                              type="password"
                              :class="this.error.newPassword? 'border border-danger':''"
                              :placeholder="$ml.get('enterYourNewPassword')"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group id="newPasswordConfirmGroup"
                            :label="`${$ml.get('newPasswordAgain')}:`"
                            label-for="newPasswordConfirm">
                <b-form-input id="newPasswordConfirm"
                              v-model="user.newPasswordConfirm"
                              type="password"
                              :class="this.error.passwordConfirm? 'border border-danger':''"
                              :placeholder="$ml.get('enterYourNewPasswordAgain')"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <p class="mb-2">{{ $ml.get('emailAddress') }}</p>
              <div class="d-flex">
                <b-form-input id="email"
                              v-model="user.email"
                              type="email"
                              :placeholder="$ml.get('enterEmailAddress')"
                              @keydown="onChangeEmail"
                              class="mb-5"></b-form-input>
                <button class="btn btn-primary btn-sm border position-absolute float-right mr-5 mt-1"
                        style="right: 0"
                        v-b-modal.modal-generalsettings-email
                        v-show="emailChanged">
                  {{ $ml.get('update') }}
                </button>
              </div>
            </div>

            <div class="col-md-6">
              <p class="mb-2">{{ $ml.get('yourGSMNumber') }}</p>
              <div class="d-flex">
                <b-form-input v-model="user.cell_phone_code"
                              placeholder="90"
                              v-mask="['##']"
                              class="mb-5 mr-1"
                              style="width: 70px"
                              @keydown="onChangePhoneCode"></b-form-input>
                <b-form-input v-model="user.cell_phone"
                              v-mask="['### ### ## ##']"
                              placeholder="555 555 55 55"
                              class="mb-5"
                              @keydown="onChangePhone"></b-form-input>
                <button class="btn btn-primary btn-sm border position-absolute float-right mr-5 mt-1"
                        style="right: 0"
                        v-b-modal.modal-generalsettings-phone
                        v-show="phoneChanged">
                  {{ $ml.get('update') }}
                </button>
              </div>
            </div>

            <div class="col-md-6">
              <b-form-group id="genderGroup"
                            :label="`${$ml.get('gender')}:`"
                            label-for="gender">
                <multiselect id="gender"
                             v-model="selectedGender"
                             :options="gender"
                             :allowEmpty="false"
                             :show-labels="false"
                             :searchable="false"
                             :placeholder="$ml.get('pleaseSelectGender')"
                             :custom-label="customGenderLabel"
                             :class="this.error.gender? 'border border-danger':''"
                             required></multiselect>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <!-- <b-form-group id="birthDateGroup"
                            :label="`${$ml.get('birthDate')}:`"
                            label-for="birthDate">
                <b-form-input id="birthDate"
                              v-model="user.birthday"
                              v-mask="['##/##/####']"
                              required
                              :placeholder="$ml.get('enterYourBirthdate')"></b-form-input>
              </b-form-group> -->

              <b-form-group :label="$ml.get('birthDate')">
                <DateTime label="birthday"
                          placeholder="dateFormat"
                          v-model="user.birthday"
                          :defaultValue="user.birthday"
                          valueFormat="YYYY-MM-DD"
                          :langVal="$ml.current"></DateTime>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-3">
            <div class="d-flex">
              <div class="d-flex align-items-center text-dark font-weight-bold font-size-lg">
                {{ $ml.get('preferences') }}
              </div>
              <div class="d-flex align-items-center ml-auto setting-arrow d-md-none">
                <i class="fas"
                   :class="this.mobileVisiblePreferences ? ' fa-angle-down':' fa-angle-right'"
                   @click="changeOnmobileVisiblePreferences()"></i>
              </div>
            </div>
            <hr />
          </div>

          <div class="preferences-settings row col-12 m-0 p-0"
               :class="this.mobileVisiblePreferences ? '':'d-none d-md-flex'">
            <div class="col-md-6">
              <b-form-group id="countryGroup"
                            :label="$ml.get('country')"
                            label-for="country">
                <multiselect id="country"
                             v-model="selectedCountry"
                             :options="countries"
                             :allowEmpty="false"
                             :show-labels="false"
                             :searchable="false"
                             :placeholder="$ml.get('pleaseSelect')"
                             :custom-label="customCountryLabel"
                             required>
                </multiselect>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group id="langGroup"
                            :label="$ml.get('language')"
                            label-for="lang">
                <multiselect id="lang"
                             v-model="selectedLang"
                             :options="lang"
                             :allowEmpty="false"
                             :show-labels="false"
                             :searchable="false"
                             :placeholder="$ml.get('pleaseSelect')"
                             :custom-label="customLangLabel"
                             required></multiselect>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <p class="mb-2">{{ $ml.get('currency') }}</p>
              <div class="d-flex">
                <b-form-input id="currency"
                              v-model="selectedCurrency"
                              type="text"
                              :placeholder="$ml.get('enterYourCurrency')"
                              @keydown="onChangeCurrency"
                              class="mb-5"></b-form-input>
                <button class="btn btn-primary btn-sm border position-absolute float-right mr-5 mt-1"
                        style="right: 0"
                        v-b-modal.modal-currency-change
                        v-show="currencyChanged">
                  {{ $ml.get('changeRequest') }}
                </button>
              </div>
            </div>

            <!-- Disable in Virtual DOM -->
            <div class="col-md-4"
                 v-if="false">
              <b-form-group id="automaticTimeZoneGroup"
                            label="Automatic Time Zone"
                            label-for="automaticTimeZone">
                <SwitchButton class="switch-success"
                              name="automaticTimeZone"
                              v-model="automaticTimeZone">
                  GMT +1:00
                </SwitchButton>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="button-collapse"
             :class="this.mobileVisibleBasic || this.mobileVisiblePreferences ? '':'d-none d-md-flex'">
          <!-- <b-button type="reset"
                  class="px-10 mr-3"
                  variant="light">Cancel</b-button> -->
          <b-button type="submit"
                    class="px-5 w-mb-100 btn-sm rounded-sm"
                    variant="primary"
                    @click="sendGeneralSettings"
                    :disabled="ajaxLoader">{{ $ml.get('saveChanges') }}</b-button>
        </div>
      </div>
    </template>
    <GeneralSettingsPhoneModal></GeneralSettingsPhoneModal>
    <GeneralSettingsEmailModal></GeneralSettingsEmailModal>
    <GeneralSettingsCurrencyModal :currency="selectedCurrency"></GeneralSettingsCurrencyModal>
  </Card>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import moment from 'moment'

import Card from '@/components/Card'
import CardLoader from '@/view/content/CardLoader'
import SwitchButton from '@/components/buttons/SwitchButton'
import GeneralSettingsPhoneModal from '@/components/GeneralSettingsPhoneModal'
import GeneralSettingsEmailModal from '@/components/GeneralSettingsEmailModal'
import GeneralSettingsCurrencyModal from '@/components/GeneralSettingsCurrencyModal'
import EventBus from '@/event-bus'
const basicValidate = require('basic-validate-js')

export default {
  name: 'GeneralSettings',
  data() {
    return {
      ajaxLoader: false,
      emailChanged: false,
      phoneChanged: false,
      currencyChanged: false,
      user: {},
      selectedCountry: '',
      selectedLang: '',
      selectedCurrency: '',
      selectedGender: '',
      gender: [
        { desc: 'Female', value: 'Female' },
        { desc: 'Male', value: 'Male' },
      ],
      countries: [],
      lang: [
        { desc: 'Turkish', value: 'tr' },
        { desc: 'English (Global)', value: 'us' },
        { desc: 'Dutch', value: 'nl' },
        { desc: 'English (Australia)', value: 'au' },
        { desc: 'English (New Zealand)', value: 'nz' },
      ],
      currency: ['USD', 'TRY'],
      mobileVisibleBasic: false,
      mobileVisiblePreferences: false,
      error: {
        gender: false,
        password: false,
        newPassword: false,
        passwordConfirm: false,
      },
      validPassword: {
        strongNumber: 0,
        isMinOneUpper: false,
        isMinOneLower: false,
        isMinEightChar: false,
        isMinOneNumber: false,
        isMinOneSpecial: false,
      },

      // Dummy Data
      // automaticTimeZone: true,
    }
  },
  created() {
    this.getCountries()
      .then((res) => {
        this.setCountries(res)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    changePassword() {
      this.error.newPassword = false
      // password control regex
      let isContainNumReg = /(?=.*[0-9])/gm
      let isContainSpecialReg = /(?=.*[$&+,:;=?@#|'<>.^*()%!-_~¨₺€/])/gm
      let isContainUpper = /[A-Z]/gm
      let isContainLower = /[a-z]/gm

      // set valid
      if (this.user.newPassword.length >= 8) {
        this.validPassword.isMinEightChar || this.validPassword.strongNumber++
        this.validPassword.isMinEightChar = true
      } else {
        !this.validPassword.isMinEightChar || this.validPassword.strongNumber--
        this.validPassword.isMinEightChar = false
        this.error.newPassword = true
      }

      if (
        basicValidate.patternControl(this.user.newPassword, isContainNumReg)
      ) {
        this.validPassword.isMinOneNumber || this.validPassword.strongNumber++
        this.validPassword.isMinOneNumber = true
      } else {
        !this.validPassword.isMinOneNumber || this.validPassword.strongNumber--
        this.validPassword.isMinOneNumber = false
        this.error.newPassword = true
      }

      if (
        basicValidate.patternControl(this.user.newPassword, isContainSpecialReg)
      ) {
        this.validPassword.isMinOneSpecial || this.validPassword.strongNumber++
        this.validPassword.isMinOneSpecial = true
      } else {
        !this.validPassword.isMinOneSpecial || this.validPassword.strongNumber--
        this.validPassword.isMinOneSpecial = false
        this.error.newPassword = true
      }

      if (basicValidate.patternControl(this.user.newPassword, isContainUpper)) {
        this.validPassword.isMinOneUpper || this.validPassword.strongNumber++
        this.validPassword.isMinOneUpper = true
      } else {
        !this.validPassword.isMinOneUpper || this.validPassword.strongNumber--
        this.validPassword.isMinOneUpper = false
        this.error.newPassword = true
      }

      if (basicValidate.patternControl(this.user.newPassword, isContainLower)) {
        this.validPassword.isMinOneLower || this.validPassword.strongNumber++
        this.validPassword.isMinOneLower = true
      } else {
        !this.validPassword.isMinOneLower || this.validPassword.strongNumber--
        this.validPassword.isMinOneLower = false
        this.error.newPassword = true
      }
    },
    formValidate() {
      this.error.gender = false
      if (!this.selectedGender || this.selectedGender.value == '') {
        this.error.gender = true
      }
      this.error.password = false
      if (this.user.currentPassword == '' && this.user.newPassword) {
        this.error.password = true
      }

      this.error.newPassword = false
      this.error.passwordConfirm = false
      if (this.user.newPassword != this.user.newPasswordConfirm) {
        this.error.newPassword = true
        this.error.passwordConfirm = true
      }

      return (
        this.error.gender || this.error.password || this.error.passwordConfirm
      )
    },
    sendGeneralSettings() {
      if (!this.formValidate()) {
        this.ajaxLoader = true
        Promise.all([this.userUpdate(), this.userPasswordUpdate()])
          .then((result) => {
            this.ajaxLoader = false
            this.generateToast(
              'success',
              this.$ml.get('updatedGeneralSettingsSuccess')
            )
          })
          .catch((err) => {
            if (err) {
              for (var key in err) {
                if (key == 'password') this.error.newPassword = true
              }
            }
            this.ajaxLoader = false
            //this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          })
      }
    },
    userUpdate() {
      return new Promise((resolve, reject) => {
        let selected_locale = this.selectedLang.value
        if (typeof selected_locale === 'undefined') {
          selected_locale = this.selectedLang[0].value
        }
        let selected_gender = this.selectedGender.value

        //ApiService.put(`users/${this.user.id}`, {
        ApiService.put(`users/me`, {
          name: this.user.name,
          surname: this.user.surname,
          gender: selected_gender.toUpperCase(),
          birthday: this.user.birthday,
          // birthday: moment(this.user.birthday, 'MM-DD-YYYY').format(
          //   'YYYY-MM-DD'
          // ),
          country_code: this.selectedCountry.code,
          default_locale: selected_locale,
        })
          .then((res) => {
            let selectedLang = selected_locale
            if (
              selectedLang !== 'tr' &&
              selectedLang !== 'en' &&
              selectedLang !== 'nl'
            ) {
              selectedLang = 'en'
            }
            window.localStorage.setItem('currentLanguage', selectedLang)
            EventBus.$emit('changeLanguage', selectedLang)
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    userPasswordUpdate() {
      if (this.user.currentPassword != '' && this.user.newPassword != '') {
        return new Promise((resolve, reject) => {
          ApiService.put('users/change-password', {
            old_password: this.user.currentPassword,
            password: this.user.newPassword,
            password_confirmation: this.user.newPasswordConfirm,
          })
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
    onChangeEmail(event) {
      this.emailChanged = true
      event.preventDefault()
    },
    onChangePhoneCode(event) {
      this.phoneChanged = true
      event.preventDefault()
    },
    onChangePhone(event) {
      this.phoneChanged = true
      event.preventDefault()
    },
    onChangeCurrency(event) {
      this.currencyChanged = true
      //event.preventDefault()
    },
    customLangLabel({ desc, value }) {
      return `${value.toUpperCase()} - ${desc}`
    },
    customCountryLabel({ name }) {
      return name
    },
    customGenderLabel({ desc, value }) {
      if (value) return this.$ml.get(value.toLowerCase())
      else return '-'
    },
    getCountries() {
      return new Promise((resolve, reject) => {
        ApiService.get('countries')
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    setCountries(res) {
      this.countries = res.data
    },
    changeOnmobileVisibleBasic() {
      this.mobileVisibleBasic = !this.mobileVisibleBasic
    },
    changeOnmobileVisiblePreferences() {
      this.mobileVisiblePreferences = !this.mobileVisiblePreferences
    },
  },
  computed: {
    ...mapGetters(['myUser', 'isLoading']),
  },
  watch: {
    myUser() {
      this.user = this.myUser

      // Capitalize gender value
      let g = this.gender.filter(
        (f) =>
          f.value ==
          (this.user.gender
            ? this.user.gender.charAt(0).toUpperCase() +
              this.user.gender.slice(1)
            : '')
      )

      if (g) {
        this.selectedGender = g[0]
      }

      // Format birthday date
      this.user.birthday = moment(this.user.birthday).format('YYYY-MM-DD')

      this.selectedCountry = this.countries.filter(
        (f) => f.code == this.user.country_code
      )

      let selectedDefault_locale = this.user.default_locale.toLowerCase()
      if (selectedDefault_locale === 'en') {
        selectedDefault_locale = 'us'
      }

      this.selectedLang = this.lang.filter(
        (f) => f.value == selectedDefault_locale
      )
      this.selectedCurrency = this.user.country.data.currency_code

      // add passwords in user data
      if (!this.user.currentPassword) {
        this.user.currentPassword = ''
      }
      if (!this.user.newPassword) {
        this.user.newPassword = ''
      }
      this.user.newPasswordConfirm = ''
    },
  },
  components: {
    Card,
    CardLoader,
    SwitchButton,
    GeneralSettingsPhoneModal,
    GeneralSettingsEmailModal,
    GeneralSettingsCurrencyModal,
  },
}
</script>

<style lang="scss" scoped>
.general-setttings-collapse {
  .button-collapse {
    width: 100%;
    text-align: right;
  }
  .setting-arrow {
    // display: none !important;
    i {
      color: #212121;
      cursor: pointer;
    }
  }
  @media (max-width: 767px) {
    .button-collapse {
      width: 100%;
      text-align: center;
    }
    // .setting-arrow {
    //   display: inline !important;
    // }
  }
}
</style>